import React, { useEffect, useState } from "react";
import { Button, Col, Form, Modal, ModalBody, ModalHeader, Spinner } from "reactstrap";
import { uploadCertificateFile } from "../../../Stafflist/StaffData";
import { Icon } from "../../../../components/Component";
import { iconsType } from "../../../Resources/ResourceData";
import { reUploadPendingCertificateAPI } from "../../Certificate/CertificateData";
import { useForm } from "react-hook-form";
import { AppText, showErrorToast, bytesToMegaBytes } from "../../../../utils";

const AddDocument = ({ visible, onClosed, onSuccess, item }) => {
  const { handleSubmit } = useForm();

  const [isLoading, setIsLoading] = useState(false);
  const [uploadedFiles, setUploadedFiles] = useState([]);
  // eslint-disable-next-line no-unused-vars
  const [removeFileIds, setRemoveFileIds] = useState([]);

  useEffect(() => {
    if (visible) {
      setUploadedFiles([]);
    }
  }, [visible]);

  const handleFormSubmit = () => {
    let fileId = null;
    if (uploadedFiles.length > 0 && !uploadedFiles[0].isOldFile) {
      fileId = uploadedFiles[0].id;
    }
    setIsLoading(true);
    let params = {
      id: item?.id,
      fileId: fileId,
    };
    reUploadPendingCertificateAPI(params, (data, error) => {
      if (error === null) {
        onSuccess();
        onClosed();
      } else {
        showErrorToast(error.message);
      }
      setIsLoading(false);
    });
  };

  const uploadFile = (file, index) => {
    uploadCertificateFile(file, "MANUAL_COURSE_CERTIFICATE", (data, error) => {
      const updatedfiles = [...uploadedFiles];
      if (error === null) {
        file.id = data.id;
        file.isLoading = false;
        file.fileUrl = data.fileUrl;
        updatedfiles[index] = file;
        setUploadedFiles(updatedfiles);
      } else {
        updatedfiles.splice(index, 1);
        setUploadedFiles(updatedfiles);
        showErrorToast(error.message.length > 0 ? error.message : AppText.connectError);
      }
    });
  };

  const handleFileSelect = (file, rootIndex) => {
    if (bytesToMegaBytes(file.size) > 100) {
      showErrorToast(AppText.maxFileSizeError);
    } else {
      const fileDetails = file;
      fileDetails.isLoading = true;
      fileDetails.isError = false;
      fileDetails.id = null;
      fileDetails.isOldFile = false;

      const updatedfiles = [...uploadedFiles]; // Create a copy of the files array

      if (updatedfiles.length > 0) {
        updatedfiles[0] = fileDetails; // Replace the existing file with the new one
      } else {
        updatedfiles.push(fileDetails); // If no file is uploaded, simply push the new file
      }
      setUploadedFiles(updatedfiles);

      // for API
      uploadFile(fileDetails, updatedfiles.length - 1);
    }
  };

  const handleFileRemove = (ind) => {
    const updatedfiles = [...uploadedFiles];

    removeFileIds.push(updatedfiles[ind].id);

    updatedfiles.splice(ind, 1);

    setUploadedFiles(updatedfiles);
  };

  const openFileInNewTab = (file) => {
    if (file) {
      window.open(file?.fileUrl, "_blank");
    }
  };

  const close = () => {
    onClosed();
  };

  return (
    <Modal
      isOpen={visible}
      toggle={() => close()}
      // onClick={() => handleFormSubmit()}
      className="modal-md add-face-event-modal modal-dialog-scrollable"
    >
      <ModalHeader className="bg-purple text-white" toggle={onClosed}>
        <p className="title">Upload Certificate</p>
      </ModalHeader>
      <ModalBody>
        <Form className="row gy-4" onSubmit={handleSubmit(handleFormSubmit)}>
          <Col className="col-12  mb-3">
            <div className="form-group mb-0">
              <label htmlFor="files">
                <Icon name="clip" className="mb-1 fs-6 text-primary" />
                <span className="text-primary"> Attach document</span>
              </label>
              <input
                type="file"
                name="files"
                accept=".pdf,.png,.jpg,.jpeg,.svg,.webp,.heic,.doc,.docx"
                className="form-control"
                id={"files"}
                style={{ display: "none" }}
                onChange={(e) => {
                  let temp = Array.prototype.slice.call(e.target.files);
                  if (temp.length > 0) {
                    handleFileSelect(temp[0]);
                  }
                }}
              />
            </div>
            {uploadedFiles?.length > 0 &&
              uploadedFiles?.map((file, ind) => {
                return (
                  <React.Fragment key={`file-${ind}`}>
                    <div className="nk-upload-item">
                      <div className="nk-upload-icon">
                        {file?.mimeType ? (
                          <>{iconsType[file?.mimeType] ? iconsType[file?.mimeType] : iconsType["others"]}</>
                        ) : (
                          <>{iconsType[file?.type] ? iconsType[file?.type] : iconsType["others"]}</>
                        )}
                      </div>
                      <div className="nk-upload-info">
                        <div className="nk-upload-title">
                          <span className="title" style={{ cursor: "pointer" }} onClick={() => openFileInNewTab(file)}>
                            {file?.name ? file?.name : "Ticket"}
                          </span>
                        </div>
                      </div>
                      <div className="nk-upload-action">
                        <a
                          href="#delete"
                          onClick={(ev) => {
                            ev.preventDefault();
                          }}
                          className="btn btn-icon btn-trigger round-40  d-flex justify-content-center align-items-center"
                        >
                          {file?.isLoading ? (
                            <Spinner size="sm" color="dark" />
                          ) : (
                            <span onClick={() => handleFileRemove(ind)}>
                              <Icon name="trash"></Icon>
                            </span>
                          )}
                        </a>
                      </div>
                    </div>
                  </React.Fragment>
                );
              })}
          </Col>
          <Col size="12">
            <ul className="align-center flex-wrap flex-sm-nowrap gx-2 gy-2">
              <li>
                <Button color="primary" type="submit" className="custom-solid-btn-sm" disabled={isLoading}>
                  {isLoading ? <Spinner size="sm" color="dark" /> : "Save"}
                </Button>
              </li>
              <li>
                <Button color="lighter text-gray" type="button" disabled={isLoading} onClick={() => close()}>
                  Cancel
                </Button>
              </li>
            </ul>
          </Col>
        </Form>
      </ModalBody>
    </Modal>
  );
};
export default AddDocument;
