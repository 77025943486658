import React, { useEffect, useState } from "react";
import Head from "../../../layout/head/Head";
import Content from "../../../layout/content/Content";
import moment from "moment";
import {
  getPendingCertificateList,
  pendingCertificateApproveAPI,
  pendingCertificateRejectAPI,
} from "./CertificateData";
import {
  Block,
  BlockBetween,
  BlockHead,
  BlockHeadContent,
  BlockTitle,
  DataTable,
  DataTableBody,
  DataTableHead,
  DataTableItem,
  DataTableRow,
  PaginationComponent,
  PreviewAltCard,
  RSelect,
} from "../../../components/Component";
import AddDocument from "../Training/WizardForms/AddDocument";
import Swal from "sweetalert2";
import { CourseDropDown } from "../../Facetoface/CourseDropDown";
import DatePicker from "react-datepicker";
import { useDispatch } from "react-redux";
import { setIsPending } from "../../../redux/slices/certificateSlice";
import LoadingComponent from "../../../components/spinner/LoadingSpinner";
import { AppText, CertificateStatusOptionWithAll, capitalizeFirstLetter, showErrorToast, UserAccountType } from "../../../utils";
import { Storage } from "../../../services/storage/storage";
import { MoreOptions } from "../../../components/moreoption/MoreOption";

const PendingCertificate = () => {
  const dispatch = useDispatch();
  const user = Storage.getLoggedInUser();
  const [documentModal, setDocumentModal] = useState(false);
  const [certificates, setCertificates] = useState([]);
  const [isInitScreen, setIsInitScreen] = useState(true);
  const [selectedItem, setSelectedItem] = useState(null);
  const [searchText, setSearchText] = useState("");
  const [status, setStatus] = useState(CertificateStatusOptionWithAll[0]);
  const [course, setCourse] = useState({
    id: null,
    label: "All",
    value: "",
  });

  const [currentPage, setCurrentPage] = useState(1);
  const [totalItems, setTotalItems] = useState(0);
  const [rangeDate, setRangeDate] = useState({
    start: null,
    end: null,
  });
  const [itemPerPage] = useState(20);

  useEffect(() => {
    getPendingCertificates();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage]);

  useEffect(() => {
    if (isInitScreen) return;
    if (currentPage === 1) {
      getPendingCertificates();
    } else {
      setCurrentPage(1);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [course, status, searchText, rangeDate]);

  const getPendingCertificates = () => {
    let params = {
      pageInfo: {
        pageIndex: currentPage,
        itemsPerPage: itemPerPage,
      },
      isLearner: user?.accountType === UserAccountType.staff ? true : false,
      courseId: course?.id,
      startDate: rangeDate.start ? moment(rangeDate.start).format("YYYY-MM-DD") : null,
      endDate: rangeDate.end ? moment(rangeDate.end).format("YYYY-MM-DD") : null,
      search: searchText,
      status: status?.value ?? "",
    };
    getPendingCertificateList(params, (data, error) => {
      if (error === null) {
        setCertificates(data?.list ?? []);
        if (currentPage === 1) {
          setTotalItems(data?.pageInfo?.totalItems ?? 0);
        }
      }
      setIsInitScreen(false);
    });
  };

  const onDownloadClick = (certificateUrl) => {
    if (certificateUrl === null || certificateUrl === undefined) {
      return;
    }
    if (certificateUrl) {
      window.open(certificateUrl, "_blank");
    } else {
      window.open(certificateUrl, "_blank");
    }
  };

  const onModerateStatus = (item, status) => {
    Swal.fire({
      title: `Are you sure?`,
      text: `You want to ${status.toLowerCase()} this certificate?`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: `Yes, ${status.toLowerCase()} it!`,
      confirmButtonColor: status === "APPROVE" ? "#28a745" : "#dc3545",
      cancelButtonText: "No",
    }).then((result) => {
      if (result.isConfirmed) {
        if (status === "APPROVE") {
          pendingCertificateApproveAPI({ id: item.id }, (data, error) => {
            if (error === null) {
              getPendingCertificates();
              dispatch(setIsPending(data?.totalPending));
            } else {
              showErrorToast(error.message);
            }
          });
        } else if (status === "REJECT") {
          pendingCertificateRejectAPI({ id: item.id }, (data, error) => {
            if (error === null) {
              dispatch(setIsPending(data?.totalPending));

              getPendingCertificates();
            } else {
              showErrorToast(error?.message ?? AppText.connectError);
            }
          });
        }
      } else {
        console.log("Action Cancelled!", result);
      }
    });
  };

  const handleUploadDocModal = (item) => {
    setSelectedItem(item);
    setDocumentModal(true);
  };

  const paginate = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  return (
    <React.Fragment>
      <Head title="certificate-list"></Head>
      {!isInitScreen && (
        <Content>
          <BlockHead>
            <BlockBetween>
              <BlockHeadContent>
                <BlockTitle tag="h3" page>
                  Pending Certificates
                </BlockTitle>
              </BlockHeadContent>
              <BlockHeadContent>
                <div className="form-group">
                  <div className="form-control-wrap d-flex gap-1">
                    <input
                      className="form-control "
                      type="text"
                      name="search"
                      autoComplete="off"
                      value={searchText}
                      placeholder="Search"
                      onChange={(e) => {
                        setSearchText(e.target.value);
                      }}
                    />
                    <DatePicker
                      selectsRange
                      toggleCalendarOnIconClick
                      startDate={rangeDate.start}
                      endDate={rangeDate.end}
                      onChange={(dates) => {
                        const [start, end] = dates;
                        setRangeDate({ start: start, end: end });
                      }}
                      isClearable
                      className="form-control date-picker"
                    />

                    <RSelect
                      options={CertificateStatusOptionWithAll}
                      value={status}
                      className="w-130px "
                      placeholder="Status"
                      onChange={(e) => setStatus(e)}
                    />
                    <CourseDropDown
                      className="w-200"
                      isClearable
                      isAllOption
                      value={course}
                      onSelect={(e) => {
                        setCourse(e);
                      }}
                    />
                  </div>
                </div>
              </BlockHeadContent>
            </BlockBetween>
          </BlockHead>
          <Block>
            {certificates?.length > 0 ? (
              <DataTable className="card-stretch">
                <DataTableBody>
                  <DataTableHead>
                    <DataTableRow>
                      <span className="sub-text">Course Name</span>
                    </DataTableRow>
                    <DataTableRow>
                      <span className="sub-text">Staff</span>
                    </DataTableRow>
                    <DataTableRow>
                      <span className="sub-text">Course Completion Date</span>
                    </DataTableRow>
                    <DataTableRow>
                      <span className="sub-text">Last Certificate Upload Date</span>
                    </DataTableRow>
                    <DataTableRow>
                      <span className="sub-text">Moderate by</span>
                    </DataTableRow>
                    <DataTableRow>
                      <span className="sub-text">Status</span>
                    </DataTableRow>
                    <DataTableRow>
                      <span className="sub-text text-center">Action</span>
                    </DataTableRow>
                  </DataTableHead>

                  {/* Display certificates list */}
                  {certificates?.map((item, index) => (
                    <DataTableItem key={index}>
                      <DataTableRow>
                        <div className="user-card">
                          <div className="user-info">
                            <span className="tb-lead">{item.courseName}</span>
                          </div>
                        </div>
                      </DataTableRow>
                      <DataTableRow>
                        <div className="user-card">
                          <div className="user-info">
                            <span className="tb-lead">
                              {(item?.userData?.firstName ?? "") + " " + (item?.userData?.lastName ?? "")}
                            </span>
                            <span>{item?.userData?.email}</span>
                          </div>
                        </div>
                      </DataTableRow>
                      <DataTableRow>
                        <div className="user-info">
                          <span className="tb-lead">{moment(item?.courseCompletionDate).format("DD-MM-YYYY")}</span>
                        </div>
                      </DataTableRow>
                      <DataTableRow>
                        <div className="user-info">
                          <span className="tb-lead">
                            {moment(item?.lastCertificateUploadDate).format("DD-MM-YYYY")}
                          </span>
                        </div>
                      </DataTableRow>
                      <DataTableRow>
                        <div className="user-info">
                          <span className="tb-lead">
                            {item?.approveBy ? `${item.approveBy.firstName} ${item.approveBy.lastName}` : "N/A"}
                            <br />
                            {item?.approvalDate ? moment(item.approvalDate).format("DD-MM-YYYY") : ""}
                          </span>
                        </div>
                      </DataTableRow>
                      <DataTableRow size="md">
                        <div className="user-info">
                          <span
                            className={`badge bg-${
                              item?.status === "APPROVED"
                                ? "success"
                                : item?.status === "PENDING"
                                ? "warning"
                                : "danger"
                            }`}
                          >
                            {user?.accountType === UserAccountType.staff && item?.status === "PENDING"
                              ? "Pending for approval"
                              : capitalizeFirstLetter(item?.status)}
                          </span>
                        </div>
                      </DataTableRow>

                      <DataTableRow className={"text-center"}>
                        <MoreOptions
                          options={[
                            ...((user?.accountType === UserAccountType.admin ||
                              user?.accountType === UserAccountType.manager) &&
                            item?.status === "PENDING"
                              ? [
                                  {
                                    icon: "check",
                                    text: "Approve",
                                    action: () => {
                                      onModerateStatus(item, "APPROVE");
                                    },
                                  },
                                  {
                                    icon: "cross",
                                    text: "Reject",
                                    action: () => {
                                      onModerateStatus(item, "REJECT");
                                    },
                                  },
                                ]
                              : []),
                            ...(item?.status === "REJECTED"
                              ? [
                                  {
                                    icon: "clip",
                                    text: "Upload Certificate",
                                    action: () => {
                                      handleUploadDocModal(item);
                                    },
                                  },
                                ]
                              : []),
                            {
                              icon: "download",
                              text: "Download Certificate",
                              action: () => {
                                onDownloadClick(item?.certificateUrl);
                              },
                            },
                          ]}
                        />
                      </DataTableRow>
                    </DataTableItem>
                  ))}
                </DataTableBody>
                {totalItems > 2 && (
                  <div className="my-2 ms-4">
                    <PaginationComponent
                      itemPerPage={itemPerPage}
                      totalItems={totalItems}
                      paginate={paginate}
                      currentPage={currentPage}
                    />
                  </div>
                )}
              </DataTable>
            ) : (
              <>
                <PreviewAltCard>
                  <div className="card-inner">
                    <div className="text-center">
                      <span className="text-silent">No data found</span>
                    </div>
                  </div>
                </PreviewAltCard>
              </>
            )}
          </Block>
        </Content>
      )}

      <AddDocument
        visible={documentModal}
        isAddTraining={true}
        onClosed={() => {
          setDocumentModal(false);
        }}
        onSuccess={() => {
          getPendingCertificates();
        }}
        item={selectedItem}
      />
      <LoadingComponent isLoading={isInitScreen} />
    </React.Fragment>
  );
};

export default PendingCertificate;
