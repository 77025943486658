import React, { useEffect, useState } from "react";
import { Form, Modal, ModalBody, ModalHeader, Spinner } from "reactstrap";
import { Button, Col, Icon, Row } from "../../components/Component";
import moment from "moment";
import { iconsType } from "../Resources/ResourceData";
import { getFaceToFaceModrateBooking, uploadF2FCertificate } from "./FaceToFaceData";
import { BookingStatusType } from "./DateEvent";
import { AppText, showErrorToast, bytesToMegaBytes, FaceToFaceDuration} from "../../utils";

const ModerateBookingModal = ({ visible, trainingData, item, onClosed, onSuccess }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [uploadedFiles, setUploadedFiles] = useState([]);
  // eslint-disable-next-line no-unused-vars
  const [removeFileIds, setRemoveFileIds] = useState([]);

  useEffect(() => {
    setUploadedFiles([]);
  }, [visible]);

  const uploadFile = (file, index) => {
    uploadF2FCertificate(file, (data, error) => {
      const updatedfiles = [...uploadedFiles];
      if (error === null) {
        file.id = data.id;
        file.isLoading = false;
        file.fileUrl = data.fileUrl;
        updatedfiles[index] = file;
        setUploadedFiles(updatedfiles);
      } else {
        updatedfiles.splice(index, 1);
        setUploadedFiles(updatedfiles);
        showErrorToast(error.message.length > 0 ? error.message : AppText.connectError);
      }
    });
  };

  const handleFormSubmit = () => {
    let fileId = null;
    if (uploadedFiles.length > 0 && !uploadedFiles[0].isOldFile) {
      fileId = uploadedFiles[0].id;
    }
    if (!fileId) {
      return;
    }
    let params = {
      bookingId: item?.id,
      status: BookingStatusType.attended,
      fileId: fileId,
    };
    setIsLoading(true);
    getFaceToFaceModrateBooking(params, (data, error) => {
      if (error === null) {
        onClosed();
        onSuccess();
      } else {
        showErrorToast(error.message?.length > 0 ? error.message : AppText.connectError);
      }
      setIsLoading(false);
    });
  };

  // const handleFileSelect = (file) => {
  //   if (bytesToMegaBytes(file.size) > 100) {
  //     showErrorToast(AppText.maxFileSizeError);
  //   } else {
  //     // Check if there is already an uploaded file
  //     if (uploadedFiles.length > 0) {
  //       // If there is, remove it
  //       handleFileRemove(0);
  //     }
  //     const fileDetails = file;
  //     fileDetails.isLoading = true;
  //     fileDetails.isError = false;
  //     fileDetails.id = null;
  //     fileDetails.isOldFile = false;

  //     // Upload the new file
  //     uploadFile(fileDetails, 0);
  //   }
  // };

  const handleFileSelect = (file, rootIndex) => {
    if (bytesToMegaBytes(file.size) > 100) {
      showErrorToast(AppText.maxFileSizeError);
    } else {
      const fileDetails = file;
      fileDetails.isLoading = true;
      fileDetails.isError = false;
      fileDetails.id = null;
      fileDetails.isOldFile = false;

      const updatedfiles = [...uploadedFiles]; // Create a copy of the files array

      if (updatedfiles.length > 0) {
        updatedfiles[0] = fileDetails; // Replace the existing file with the new one
      } else {
        updatedfiles.push(fileDetails); // If no file is uploaded, simply push the new file
      }
      setUploadedFiles(updatedfiles);

      // for API
      uploadFile(fileDetails, updatedfiles.length - 1);
    }
  };

  const handleFileRemove = (ind) => {
    const updatedfiles = [...uploadedFiles];

    removeFileIds.push(updatedfiles[ind].id);

    updatedfiles.splice(ind, 1);

    setUploadedFiles(updatedfiles);
  };

  const openFileInNewTab = (file) => {
    if (file) {
      window.open(file?.fileUrl, "_blank");
    }
  };

  let duration = FaceToFaceDuration.find((item) => item.value === trainingData?.duration);

  return (
    <Modal isOpen={visible} toggle={() => onClosed()} className="modal-md add-face-event-modal modal-dialog-scrollable">
      <ModalHeader className="bg-purple text-white" toggle={onClosed}>
        <p className="title">{`You want to mark present for ${item?.user?.firstName} ${item?.user?.lastName}?`}</p>
      </ModalHeader>
      <ModalBody>
        <Form className="row gy-4">
          <Row md={6} className={"mt-2 mb-1"}>
            <Col className="col-5">
              <span className="profile-ud-label">Training name</span>
            </Col>
            <Col className="col-7">
              <span className="profile-ud-value fw-bold">{trainingData?.title}</span>
            </Col>
          </Row>

          <Row md={6} className={"mt-2 mb-1"}>
            <Col className="col-5">
              <span className="profile-ud-label">Date / Time</span>
            </Col>
            <Col className="col-7">
              <span className="profile-ud-value fw-bold">
                {moment(trainingData?.dateTime ?? "").format("DD-MM-YYYY / HH:mm A")}
              </span>
            </Col>
          </Row>

          <Row md={6} className={"mt-2"}>
            <Col className="col-5">
              <span className="profile-ud-label">Duration</span>
            </Col>
            <Col className="col-7">
              <span className="profile-ud-value fw-bold">{duration?.label ?? ""}</span>
            </Col>
          </Row>

          <Col className="col-12 mb-3">
            <div className="form-group mb-0">
              <label htmlFor="files">
                <Icon name="clip" className="mb-1 fs-6 text-primary" />
                <span className="text-primary"> Attach certificate</span>
              </label>
              <input
                type="file"
                name="files"
                accept=".pdf,.png,.jpg,.jpeg,.svg,.webp,.heic,.doc,.docx"
                // accept=".pdf,.png,.jpg,.jpeg,.svg,.webp,.heic"
                className="form-control"
                id={"files"}
                style={{ display: "none" }}
                onChange={(e) => {
                  let temp = Array.prototype.slice.call(e.target.files);
                  if (temp.length > 0) {
                    handleFileSelect(temp[0]);
                  }
                }}
              />
            </div>

            {uploadedFiles?.length > 0 &&
              uploadedFiles?.map((file, ind) => {
                return (
                  <React.Fragment key={`file-${ind}`}>
                    <div className="nk-upload-item">
                      <div className="nk-upload-icon">
                        {file?.isOldFile ? (
                          <>{iconsType[file?.mimeType] ? iconsType[file?.mimeType] : iconsType["others"]}</>
                        ) : (
                          <>{iconsType[file?.type] ? iconsType[file?.type] : iconsType["others"]}</>
                        )}
                      </div>
                      <div className="nk-upload-info">
                        <div className="nk-upload-title">
                          <span
                            className="title"
                            style={{ cursor: "pointer" }} // Add cursor pointer style
                            onClick={() => openFileInNewTab(file)} // Handle click event
                          >
                            {file?.name}
                          </span>
                          {/* <span className="title">{file?.name}</span> */}
                        </div>
                      </div>
                      <div className="nk-upload-action">
                        <a
                          href="#delete"
                          onClick={(ev) => {
                            ev.preventDefault();
                          }}
                          className="btn btn-icon btn-trigger round-40  d-flex justify-content-center align-items-center"
                        >
                          {file?.isLoading ? (
                            <Spinner size="sm" color="dark" />
                          ) : (
                            <span onClick={() => handleFileRemove(ind)}>
                              <Icon name="trash"></Icon>
                            </span>
                          )}
                        </a>
                      </div>
                    </div>
                  </React.Fragment>
                );
              })}
          </Col>
          <Col size="12">
            <ul className="align-center flex-wrap justify-content-end flex-sm-nowrap gx-2 gy-2">
              <li>
                <Button color="lighter text-gray" type="button" disabled={isLoading} onClick={() => onClosed()}>
                  Cancel
                </Button>
              </li>
              <li>
                <Button
                  color="primary"
                  type="button"
                  className="custom-solid-btn-sm"
                  disabled={isLoading || uploadedFiles?.length === 0}
                  onClick={() => handleFormSubmit()}
                >
                  {isLoading ? <Spinner size="sm" color="dark" /> : "Save"}
                </Button>
              </li>
            </ul>
          </Col>
        </Form>
      </ModalBody>
    </Modal>
  );
};

export default ModerateBookingModal;
