import React, { useState, useEffect } from "react";
import { runWithRetry } from "../../../utils";

const TextToSpeech = ({ text }) => {
  const [isPaused, setIsPaused] = useState(false);
  const [isStop, setIsStop] = useState(false);
  const [utterance, setUtterance] = useState(null);

  const [selectedVoice, setSelectedVoice] = useState();

  useEffect(() => {
    // if (selectedVoice === undefined) {
    //   populateVoiceList();
    // }
  }, []);

  useEffect(() => {
    const synth = window.speechSynthesis;
    const u = new SpeechSynthesisUtterance(text);
    setUtterance(u);
    getVoices();

    return () => {
      synth.cancel();
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [text]);

  const getVoices = () => {
    runWithRetry(() => {
      // var voices = synth?.getVoices();
      const synth = window.speechSynthesis;
      const u = new SpeechSynthesisUtterance(text);
      var voices = synth?.getVoices();

      if ((voices?.length ?? 0) > 0) {
        let ukVoices = voices
          ?.filter((item) => item?.lang === "en-US" || item.lang === "en-GB")
          .filter(
            (item) =>
              item.voiceURI.includes("Microsoft Zira - English (United States)") ||
              item.voiceURI.includes("Nicky") ||
              item.voiceURI.includes("Shelley")
          );
        if (ukVoices?.length > 0) {
          setSelectedVoice(ukVoices[0]);
        }

        u.voice = ukVoices[0];
        setUtterance(u);
        setIsPaused(false);

        u.onend = () => {
          setIsPaused(false);
          setIsStop(false);
        };

        return true;
      }

      return false;
    });
  };

  const handlePlay = () => {
    const synth = window.speechSynthesis;
    if (!isPaused) {
      synth.resume();
    } else if (selectedVoice) {
      // utterance.voice = selectedVoice;
    }

    utterance.rate = 0.75;
    synth.speak(utterance);
    setIsPaused(true);
    setIsStop(true);
  };

  const handlePause = () => {
    const synth = window.speechSynthesis;
    synth.pause();
    setIsPaused(false);
  };

  const handleStop = () => {
    const synth = window.speechSynthesis;
    synth.cancel();
    setIsPaused(false);
    setIsStop(false);
  };

  // eslint-disable-next-line no-unused-vars
  function populateVoiceList() {
    // const synth = window.speechSynthesis;
    // var voices = synth.getVoices();
    // let ukVoices = voices.filter(
    //   (item) => item.lang === "en-GB" || item.lang === "en-UK"
    // );
    // let list = ukVoices.filter((item) =>
    //   item.voiceURI.includes("Google UK English Female")
    // );
    // if (list.length === 1) {
    //   return setSelectedVoice(list[0]);
    // }
  }

  return (
    <div className="d-flex">
      {isPaused ? (
        <>
          <span className="btn btn-dim btn-primary  me-1 mb-2 mb-sm-0" onClick={() => handlePause()}>
            <span className="ni ni-pause-circle fs-4 "></span>
          </span>
        </>
      ) : (
        <>
          <span className="btn btn-dim btn-primary  me-1 mb-2 mb-sm-0" onClick={() => handlePlay()}>
            {!isStop && <span>Listen to the Recording</span>}

            <span className={`ni ni-play-circle fs-4 ${!isStop ? "mx-1" : ""}`}></span>
          </span>
        </>
      )}
      {isStop ? (
        <span className="btn btn-dim btn-primary  mb-2 mb-sm-0" onClick={handleStop}>
          <span className="ni ni-stop-circle fs-4 "></span>
        </span>
      ) : null}
    </div>
  );
};

export default TextToSpeech;
