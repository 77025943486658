import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router";
import { Spinner } from "reactstrap";
import PageContainer from "../../layout/page-container/PageContainer";
import { Storage } from "../../services/storage/storage";
import { verifyResetPasswordToken } from "./AuthData";
import { NewPasswordType } from "../../utils";

const ResetPassword = (props) => {
  const [loading, setLoading] = useState();
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    console.log("props: ", props);
    const path = new URLSearchParams(location.search);
    const email = path.get("email");
    const token = path.get("tkn");
    if (email && token) {
      verifyResetPassword(email, token);
    } else {
      moveToLogin();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function moveToLogin() {
    Storage.clearAll();
    navigate(`${process.env.PUBLIC_URL}/auth-login`);
  }

  function verifyResetPassword(email, token) {
    setLoading(true);
    let params = { email: email, token: token };
    verifyResetPasswordToken(params, (data, error) => {
      setLoading(false);
      if (error === null) {
        navigate(`${process.env.PUBLIC_URL}/auth-create-password`, {
          state: {
            token: data.recoveryToken,
            type: NewPasswordType.forgotPassword,
          },
        });
      } else {
        moveToLogin();
      }
    });
  }
  return (
    <PageContainer>
      {loading ? (
        <div className="spinner">
          <Spinner className="spinner-border-md" color="primary" />
        </div>
      ) : (
        <></>
      )}
    </PageContainer>
  );
};

export default ResetPassword;
