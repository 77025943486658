import React, { forwardRef, useEffect, useImperativeHandle, useState } from "react";
import Head from "../../../layout/head/Head";
import Content from "../../../layout/content/Content";
import moment from "moment";
import {
  Block,
  BlockBetween,
  BlockHead,
  BlockHeadContent,
  BlockTitle,
  Button,
  DataTable,
  DataTableBody,
  DataTableHead,
  DataTableItem,
  DataTableRow,
  Icon,
  PaginationComponent,
} from "../../../components/Component";
import { archiveCertificateAPI } from "../../Stafflist/StaffData";
import { CourseDropDown } from "../../Facetoface/CourseDropDown";
import { Storage } from "../../../services/storage/storage";
import { openLinkInNewTab } from "../../../utils";
import LoadingComponent from "../../../components/spinner/LoadingSpinner";

const ArchiveCertificate = () => {
  const [course, setCourse] = useState();
  let currentUser = Storage.getLoggedInUser();

  return (
    <React.Fragment>
      <Head title="Archive-certificate"></Head>
      <Content>
        <BlockHead>
          <BlockBetween>
            <BlockHeadContent>
              <BlockTitle tag="h3" page>
                Archive Certificates
              </BlockTitle>
            </BlockHeadContent>
            <BlockHeadContent>
              <div className="form-group">
                <div className="form-control-wrap">
                  <CourseDropDown
                    className="w-200"
                    isClearable
                    value={course}
                    onSelect={(e) => {
                      setCourse(e);
                    }}
                  />
                </div>
              </div>
            </BlockHeadContent>
          </BlockBetween>
        </BlockHead>
        <Block>
          <ArchiveCertificateList staffId={currentUser?.id} courseId={course?.id} />
        </Block>
      </Content>
    </React.Fragment>
  );
};

export default ArchiveCertificate;

export const ArchiveCertificateList = forwardRef(function ArchiveCertificateList({ staffId, courseId }, ref) {
  const [certificates, setCertificates] = useState([]);
  const [isInitScreen, setIsInitScreen] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalItems, setTotalItems] = useState(0);
  const [itemPerPage] = useState(20);

  useImperativeHandle(
    ref,
    () => {
      return {
        reloadCertificatesList() {
          getMyArchiveCertificates();
        },
      };
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [staffId, courseId]
  );

  useEffect(() => {
    getMyArchiveCertificates();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage]);

  useEffect(() => {
    if (isInitScreen) return;
    if (currentPage === 1) {
      getMyArchiveCertificates();
    } else {
      setCurrentPage(1);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [courseId]);

  const getMyArchiveCertificates = () => {
    let params = {
      pageInfo: {
        pageIndex: currentPage,
        itemsPerPage: itemPerPage,
      },
      userId: staffId,
      courseId: courseId,
    };

    archiveCertificateAPI(params, (data, error) => {
      if (error === null) {
        setCertificates(data?.list);
        if (currentPage === 1) {
          setTotalItems(data?.pageInfo?.totalItems ?? 0);
        }
      }
      setIsInitScreen(false);
    });
  };

  const paginate = (pageNumber) => {
    setCurrentPage(pageNumber);
  };
  const onDownloadClick = (item, event) => {
    openLinkInNewTab(item.url, event);
  };
  return (
    <React.Fragment>
      {!isInitScreen && (
        <Block>
          <DataTable className={"course-list-view"}>
            <DataTableBody>
              <DataTableHead>
                <DataTableRow>
                  <span className="sub-text">Training name</span>
                </DataTableRow>
                <DataTableRow>
                  <span className="sub-text">Issued Date</span>
                </DataTableRow>
                <DataTableRow>
                  <span className="sub-text">Generated type</span>
                </DataTableRow>
                <DataTableRow>
                  <span className="sub-text">Generated by</span>
                </DataTableRow>
                <DataTableRow>
                  <span className="sub-text"></span>
                </DataTableRow>
              </DataTableHead>
              {/*Head*/}
              {(certificates?.length ?? 0) > 0
                ? certificates?.map((item, index) => {
                    return (
                      <DataTableItem key={index}>
                        <DataTableRow>
                          <div className="user-card">
                            <div className="user-info">
                              <span className="tb-lead">
                                {item.name}
                                {""}
                              </span>
                            </div>
                          </div>
                        </DataTableRow>
                        <DataTableRow>
                          <div className="user-info">
                            <span className="tb-lead">{moment(item.issueAt).format("DD-MM-YYYY")} </span>
                          </div>
                        </DataTableRow>
                        <DataTableRow>
                          <div className="user-info">
                            <span className="tb-lead">
                              {item?.generatedType
                                ? item?.generatedType === "MANUALLY_DATE_UPDATED"
                                  ? "MANUALLY DATE UPDATED"
                                  : item?.generatedType
                                : "N/A"}
                            </span>
                          </div>
                        </DataTableRow>
                        <DataTableRow>
                          <div className="user-info">
                            <span className="tb-lead">
                              {(item?.generatedBy?.firstName ?? "N/A") + " " + (item?.generatedBy?.lastName ?? "")}
                            </span>
                          </div>
                        </DataTableRow>
                        <DataTableRow className={"text-end"}>
                          <Button className="btn-text" color="" onClick={(event) => onDownloadClick(item, event)}>
                            <span className="fw-bold align-center">
                              PDF
                              <Icon className={"ms-1"} name="download" />
                            </span>
                          </Button>
                          <div className="user-info"></div>
                        </DataTableRow>
                      </DataTableItem>
                    );
                  })
                : null}
            </DataTableBody>
            {certificates?.length === 0 && (
              <div className="card-inner">
                <div className="text-center">
                  <span className="text-silent">No data found</span>
                </div>
              </div>
            )}
          </DataTable>
          {totalItems > 2 && (
            <div className="my-2">
              <PaginationComponent
                itemPerPage={itemPerPage}
                totalItems={totalItems}
                paginate={paginate}
                currentPage={currentPage}
              />
            </div>
          )}
        </Block>
      )}
      <LoadingComponent isLoading={isInitScreen} />
    </React.Fragment>
  );
});
