import React from "react";
import { Navigate, Outlet, useLocation } from "react-router-dom";
import { Storage } from "../services/storage/storage";
import { apiService } from "../services/api";
import { AuthPaths, AdminPaths, LearnerPaths, ManagerPaths, UserAccountType, staticPages } from "../utils";



const PrivateRoute = ({element}) => {
  let location = useLocation();
  const auth = Storage.getUserToken();
  const user = Storage.getLoggedInUser();
  apiService.setUserToken(auth);

  // console.log("location.pathname: ", location.pathname, user);
  
  if (!auth && (AuthPaths.includes(location.pathname) || staticPages.includes(location.pathname))) {
    return element;
  } else if (auth && AuthPaths.includes(location.pathname)) {
    return <Navigate to={`${process.env.PUBLIC_URL}`} />;
  } else if (auth && user && user?.accountType === UserAccountType.admin && !AdminPaths.includes(location.pathname)) {
    return <Navigate to={`${process.env.PUBLIC_URL}`} />;
  } else if (auth && user && user?.accountType === UserAccountType.manager && !ManagerPaths.includes(location.pathname)) {
    return <Navigate to={`${process.env.PUBLIC_URL}`} />;
  } else if (auth && user && user?.accountType === UserAccountType.staff && !LearnerPaths.includes(location.pathname)) {
    return <Navigate to={`${process.env.PUBLIC_URL}`} />;
  } else {
    return auth ? <Outlet /> : <Navigate to={`${process.env.PUBLIC_URL}/auth-login`} />;
  }

  // return auth ? <Outlet /> : <Navigate to={`${process.env.PUBLIC_URL}/auth-login`} />;
};

export default PrivateRoute;
