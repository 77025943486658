import { apiService } from "../../../services/api";
import { APIServicePath } from "../../../services/api/api-service-impl";

export const bulkActionOptions = [
  { value: "Active", label: "Active" },
  { value: "Paused", label: "Paused" },
  { value: "All", label: "All" },
];

export function addLocation(name, callback) {
  apiService
    .postCall(APIServicePath.addLocation, { name: name })
    .then((data) => {
      callback(data, null);
    })
    .catch((error) => {
      callback(null, error);
    });
}

export function editLocation(id, text, callback) {
  apiService
    .postCall(APIServicePath.editLocation, { id: id, name: text })
    .then((data) => {
      callback(data, null);
    })
    .catch((error) => {
      callback(null, error);
    });
}

export function deleteLocation(id, callback) {
  apiService
    .postCall(APIServicePath.deleteLocation, { id: id })
    .then((data) => {
      callback(data, null);
    })
    .catch((error) => {
      callback(null, error);
    });
}

export function locationsDropDown(isFetchAll, callback) {
  apiService
    .postCall(APIServicePath.locationList, { isAllowAll: isFetchAll })
    .then((data) => {
      let list = [];
      for (let index = 0; index < data.list?.length ?? 0; index++) {
        const element = data.list[index];
        list.push({
          id: element.id,
          label: element.name,
          value: element.name,
        });
      }
      callback(list, null);
    })
    .catch((error) => {
      callback(null, error);
    });
}

export function locationWithStatistics(page, itemPerPage, search, token, callback) {
  let params = {
    pageInfo: {
      pageIndex: page,
      itemsPerPage: itemPerPage,
    },
    search: search?.length > 0 ? search : null,
  };

  apiService
    .postCallWithCancelSignal(APIServicePath.locationStats, params, token)
    .then((data) => {
      callback(toLocationStatisticList(data), null);
    })
    .catch((error) => {
      callback(null, error);
    });
}

function toLocationStatisticList(response) {
  let list = [];
  for (let index = 0; index < response.list?.length ?? 0; index++) {
    const item = response.list[index];
    list.push(toLocationStatistic(item));
  }
  return { list: list, pageInfo: response.pageInfo };
}

function toLocationStatistic(response) {
  return {
    id: response.id,
    name: response.name,
    totalCompliance: response.totalCompliance,
    totalComplianceStaff: response.totalComplianceStaff,
    totalStaff: response.totalStaff,
    avgCompliance: response.avgCompliance,
  };
}
