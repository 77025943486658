import React, { forwardRef, useEffect, useImperativeHandle, useRef, useState } from "react";
import Head from "../../../layout/head/Head";
import Content from "../../../layout/content/Content";
import moment from "moment";
import { downloadCertificate, getMyCertificateList } from "./CertificateData";
import {
  Block,
  BlockBetween,
  BlockHead,
  BlockHeadContent,
  BlockTitle,
  Button,
  DataTable,
  DataTableBody,
  DataTableHead,
  DataTableItem,
  DataTableRow,
  Icon,
} from "../../../components/Component";
import { AppText, openLinkInNewTab, showErrorToast } from "../../../utils";
import LoadingComponent from "../../../components/spinner/LoadingSpinner";
import { Storage } from "../../../services/storage/storage";

const GeneratedCertificate = () => {
  const user = Storage.getLoggedInUser(); // Call the function to get the user data
  const listRef = useRef();

  function handleDownloadAll(ev) {
    listRef?.current?.downloadAllCertificates();
  }

  return (
    <React.Fragment>
      <Head title="Certificates List"></Head>
      <Content>
        <BlockHead>
          <BlockBetween>
            <BlockHeadContent>
              <BlockTitle tag="h3" page>
                My Certificates
              </BlockTitle>
            </BlockHeadContent>
            <BlockHeadContent>
              <Button color="primary" className="btn-icon ps-2 pe-2 custom-solid-btn-sm" onClick={handleDownloadAll}>
                Download all
              </Button>
            </BlockHeadContent>
          </BlockBetween>
        </BlockHead>
        <Block>
          <CertificatesList ref={listRef} staffId={user?.id} isLearnerSide={true} />
        </Block>
      </Content>
    </React.Fragment>
  );
};

export default GeneratedCertificate;

export const CertificatesList = forwardRef(function CertificatesList({ staffId, isLearnerSide }, ref) {
  const [certificates, setCertificates] = useState([]);
  const [isInitScreen, setIsInitScreen] = useState(true);
  const [isLoading, setIsLoading] = useState(false);

  useImperativeHandle(
    ref,
    () => {
      return {
        reloadCertificatesList() {
          getMyCertificates();
        },
        downloadAllCertificates() {
          downloadUserAllCertificates();
        },
      };
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [staffId]
  );

  useEffect(() => {
    getMyCertificates();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onDonwloadClick = (item, event) => {
    openLinkInNewTab(item.url, event);
  };

  const getMyCertificates = () => {
    let params = { userId: staffId };
    getMyCertificateList(params, (data, error) => {
      if (error === null) {
        setCertificates(data);
      }
      setIsInitScreen(false);
    });
  };

  const downloadUserAllCertificates = () => {
    if (isLoading) {
      return;
    }
    
    // if (list.length === 0) {
    //   showErrorToast("No certificates available for download at this time.");
    //   return;
    // }

    let params = {
      isMyCertificates: true,
    };
    setIsLoading(true);
    downloadCertificate(params, (data, error) => {
      if (error === null) {
        openLinkInNewTab(data.downloadUrl, null);
      } else {
        showErrorToast(error.message?.length > 0 ? error.message : AppText.connectError);
      }
      setIsLoading(false);
    });
  };

  return (
    <React.Fragment>
      {!isInitScreen && (
        <Block>
          <DataTable className={"course-list-view"}>
            <DataTableBody>
              <DataTableHead>
                <DataTableRow>
                  <span className="sub-text">Training name</span>
                </DataTableRow>
                <DataTableRow>
                  <span className="sub-text">Issued</span>
                </DataTableRow>
                <DataTableRow>
                  <span className="sub-text"></span>
                </DataTableRow>
              </DataTableHead>
              {/*Head*/}
              {(certificates?.length ?? 0) > 0
                ? certificates?.map((item, index) => {
                    return (
                      <DataTableItem key={index}>
                        <DataTableRow>
                          <div className="user-card">
                            <div className="user-info">
                              <span className="tb-lead">
                                {item.name}
                                {""}
                              </span>
                            </div>
                          </div>
                        </DataTableRow>
                        <DataTableRow>
                          <div className="user-info">
                            <span className="tb-lead">{moment(item.issueAt).format("DD-MM-YYYY")} </span>
                          </div>
                        </DataTableRow>
                        <DataTableRow className={"text-end"}>
                          <Button className="btn-text" color="" onClick={(event) => onDonwloadClick(item, event)}>
                            <span className="fw-bold align-center">
                              PDF
                              <Icon className={"ms-1"} name="download" />
                            </span>
                          </Button>
                          <div className="user-info"></div>
                        </DataTableRow>
                      </DataTableItem>
                    );
                  })
                : null}
            </DataTableBody>
            {certificates?.length === 0 && (
              <div className="card-inner">
                <div className="text-center">
                  <span className="text-silent">No data found</span>
                </div>
              </div>
            )}
          </DataTable>
        </Block>
      )}
      <LoadingComponent isLoading={isInitScreen} />
    </React.Fragment>
  );
});
