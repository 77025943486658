export {
  UserAccountType,
  PASSWORD_TOOL_TIP,
  AuthPaths,
  AdminPaths,
  LearnerPaths,
  ManagerPaths,
  staticPages,
  FaceToFaceDuration,
  CourseStatus,
  FDUDuration,
  TrainingType,
  ColorsArray,
  RadioOptionTypes,
  openLinkInNewTab,
  dueDateColor,
  dateToShowFormate,
  isNumberOnly,
  toCapitalize,
  showErrorToast,
  showSuccessToast,
  removeTimeFromServerDateString,
  listAsString,
  newlineText,
  dueDate,
  completedDate,
  findUpper,
  runWithRetry,
  bytesToMegaBytes,
  capitalizeFirstLetter,
  handleSignout,
} from "./Utils";

export { AppText } from "./AppText";
export const EMAIL_REGX = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;

export const StatusOptionWithAll = [
  { value: "ALL", label: "All" },
  { value: "ACTIVE", label: "Active" },
  { value: "DISABLED", label: "Paused" },
];

export const CertificateStatusOptionWithAll = [
  { value: "", label: "All" },
  { value: "APPROVED", label: "Approved" },
  { value: "PENDING", label: "Pending" },
  { value: "REJECTED", label: "Rejected" },
];

export const FTFBookingStatus = {
  applied: "APPLIED",
  absent: "ABSENT",
  attended: "ATTENDED",
};

export const NewPasswordType = {
  forgotPassword: "forgot password",
  changePassword: "change password",
};

export const QuestionType = {
  mcq: "MCQ",
  msq: "MSQ",
  tf: "TF",
};
