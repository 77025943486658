import { apiService } from "../../../services/api";
import { APIServicePath } from "../../../services/api/api-service-impl";

export const bulkActionOptions = [
  { value: "Active", label: "Active" },
  { value: "Paused", label: "Paused" },
  { value: "All", label: "All" },
];
export const CourseListName = [
  { id: 1, courses: "Engineering" },
  { id: 2, courses: "BCA" },
  { id: 4, courses: "MBA" },
  { id: 5, courses: "MBA1" },
  { id: 6, courses: "MBA2" },
  { id: 7, courses: "MBA3" },
  { id: 8, courses: "MBA4" },
  { id: 9, courses: "MBA4" },
];
export const FrequencyOptions = [
  { value: "ONE_OFF", label: "One-off" },
  { value: "EVERY_SIX_MONTHS", label: "Every 6 months" },
  { value: "EVERY_YEAR", label: "Every year" },
  { value: "EVERY_TWO_YEARS", label: "Every 2 year" },
  { value: "EVERY_THREE_YEARS", label: "Every 3 year" },
];
export const DueOptions = [
  { value: "1 DAY", label: "1 day" },
  { value: "1 WEEK", label: "1 week" },
  { value: "2 WEEKS", label: "2 weeks" },
  { value: "4 WEEKS", label: "4 weeks" },
  { value: "8 WEEKS", label: "8 weeks" },
  { value: "3 MONTHS", label: "3 months" },
  { value: "6 MONTHS", label: "6 months" },
];

export const DurationOptions = [
  { value: "10 - 20 mins", label: "10 - 20 mins" },
  { value: "20 - 30 mins", label: "20 - 30 mins" },
  { value: "30 - 40 mins", label: "30 - 40 mins" },
  { value: "40 - 50 mins", label: "40 - 50 mins" },
  { value: "50 - 60 mins", label: "50 - 60 mins" },
];

export const YesNoOptions = [
  { value: "Yes", label: "Yes" },
  { value: "No", label: "No" },
];

export const getRoleList = (isShortInfo = true, callback) => {
  apiService
    .postCall(APIServicePath.roleList, { isShortInfo: isShortInfo })
    .then((data) => {
      let list = ServerListToLocalRoleList(data.list, isShortInfo);
      callback(list, null);
    })
    .catch((error) => {
      callback(null, error);
    });
};

export const rolesDropDown = (callback) => {
  apiService
    .postCall(APIServicePath.roleList, { isShortInfo: true })
    .then((data) => {
      let list = [];
      for (let index = 0; index < data.list?.length ?? 0; index++) {
        const element = data.list[index];
        list.push({
          id: element.id,
          label: element.name,
          value: element.name,
        });
      }
      callback(list, null);
    })
    .catch((error) => {
      callback(null, error);
    });
};

export const addUserRole = (data, callback) => {
  apiService
    .postCall(APIServicePath.createRole, data)
    .then((result) => {
      let response = {
        courses: toRoleCourses(result?.data?.courses),
        id: result?.data?.id,
        name: result?.data?.name,
      };
      callback(response, null);
    })
    .catch((error) => {
      callback(null, error);
    });
};

export const removeUserRole = (id, callback) => {
  apiService
    .postCall(APIServicePath.removeRole, { id: id })
    .then((result) => {
      callback(result, null);
    })
    .catch((error) => {
      callback(null, error);
    });
};

export const editUserRole = (data, callback) => {
  apiService
    .postCall(APIServicePath.updateRole, data)
    .then((result) => {
      let response = {
        courses: toRoleCourses(result?.data?.courses),
        id: result?.data?.id,
        name: result?.data?.name,
      };
      callback(response, null);
    })
    .catch((error) => {
      callback(null, error);
    });
};

export const setDueInAndFrequence = (data, callback) => {
  apiService
    .postCall(APIServicePath.roleFrequency, data)
    .then((result) => {
      callback(result, null);
    })
    .catch((error) => {
      callback(null, error);
    });
};

export const getCourseList = (isShortInfo = true, callback) => {
  apiService
    .postCall(APIServicePath.courseList, { isShortInfo: isShortInfo })
    .then((data) => {
      let list = ServerListToLocalCourseList(data.list);
      callback(list, null);
    })
    .catch((error) => {
      callback(null, error);
    });
};

// Local Functions
const ServerListToLocalRoleList = (data, isShortInfo) => {
  let list = [];
  for (let index = 0; index < data?.length ?? 0; index++) {
    const element = data[index];
    list.push(ServerToLocalRole(element, isShortInfo));
  }
  return list;
};

const ServerToLocalRole = (data, isShortInfo) => {
  let item = {
    id: data.id,
    role: data.name,
  };
  if (!isShortInfo) {
    item.isOpen = false;
    item.courses = ServerListToLocalCourseList(data.courses);
  }
  return item;
};

const ServerListToLocalCourseList = (data) => {
  let list = [];
  for (let index = 0; index < data?.length ?? 0; index++) {
    const element = data[index];
    list.push(ServerToLocalCourse(element));
  }
  return list;
};

const ServerToLocalCourse = (data) => {
  let item = {
    id: data.id,
    title: data.name,
    dueIn: data.dueIn,
    frequency: data.frequency,
  };
  return item;
};

const toRoleCourses = (courses) => {
  let list = [];
  for (let index = 0; index < courses?.length ?? 0; index++) {
    const item = courses[index];
    if (item.dueIn) {
      let dueIn = DueOptions.filter((e) => e.value === item.dueIn);
      item.dueIn = dueIn.length === 1 ? dueIn[0] : null;
    }
    if (item.frequency) {
      let frequency = FrequencyOptions.filter((e) => e.value === item.frequency);
      item.frequency = frequency.length === 1 ? frequency[0] : null;
    }
    list.push(item);
  }
  return list;
};
