import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { Form, Spinner } from "reactstrap";
import {
  Block,
  BlockHead,
  BlockHeadContent,
  BlockTitle,
  Button,
  Col,
  Icon,
  PreviewAltCard,
} from "../../components/Component";
import Content from "../../layout/content/Content";
import Head from "../../layout/head/Head";
import { getRoleList } from "../dashboard/Roles/RolesData";
import { fetchAddPolicy, uploadPolicyDoc } from "./PolicyData";
import { AppText, showErrorToast, bytesToMegaBytes } from "../../utils";
import { useNavigate } from "react-router";

const AddPolicy = (props) => {
  const navigate = useNavigate();

  const [isTitleValid, setIsTitleValid] = useState(true);
  const [formData, setFormData] = useState({
    title: "",
  });

  const [roleList, setRoleList] = useState([]);
  const [selectAll, setSelectAll] = useState(false);
  const [policyId, setPolicyId] = useState();
  const [isUploading, setIsUploading] = useState(false);
  const [isloading, setIsloading] = useState(false);
  const [uploadedFile, setUploadedFile] = useState("");
  const [fileError, setFileError] = useState(false);

  useEffect(() => {
    loadRoles();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const showDetails = (state) => {
    setPolicyId(state?.policyId);
    setFormData((prevData) => ({ ...prevData, title: state?.name ?? "" }));
    setRoleList((prevData) =>
      prevData.map((item) => ({
        ...item,
        checked: state?.roles.some((e) => e.id === item.id) ?? false,
      }))
    );
  };

  const toggleSelectAll = (e) => {
    const newState = e.currentTarget.checked;
    setSelectAll(newState);
    setRoleList((prevData) => prevData?.map((item) => ({ ...item, checked: newState })));
  };

  const onSelectChange = (e, id) => {
    const newState = e.currentTarget.checked;
    setRoleList((prevData) => prevData.map((item) => (item.id === id ? { ...item, checked: newState } : item)));
    setSelectAll(roleList.filter((item) => item.id !== id).every((item) => item.checked) && newState);
  };

  const onInputChange = (e) => {
    setFormData((prevData) => ({ ...prevData, title: e.target.value }));
  };

  const onFormSubmit = (e) => {
    // check if the title input field is not empty
    const isTitleValid = formData.title.trim();
    setIsTitleValid(isTitleValid);

    // check if all checkboxes are selected
    const isAllChecked = roleList.some((item) => item.checked === true);

    // if the form is valid, submit it
    if (isTitleValid && isAllChecked) {
      let name = formData.title.trim();
      let ids = roleList.filter((item) => item.checked === true).map((item) => item.id);
      if (policyId) {
        // updatePolicy(policyId);
        updatePolicy(policyId, name, ids, uploadedFile?.fileId);
      } else {
        createPolicy(name, ids, uploadedFile.fileId);
      }
    }
  };

  function moveToStep2(id, name) {
    let courses = roleList.filter((item) => item.checked === true);
    props.setState("data", {
      roleId: id,
      name: name,
      courses: courses,
    });
    props.next();
  }

  //******************API************************/
  function loadRoles() {
    getRoleList(true, (data, error) => {
      if (error === null) {
        setRoleList(data);
        showDetails();
      }
    });
  }

  function createPolicy(name, ids, fileId) {
    setIsloading(true);
    let params = {
      name: name,
      fileId: fileId,
      roleIds: ids,
    };
    fetchAddPolicy(params, (data, error) => {
      if (error === null) {
        setIsloading(false);
        navigate(`${process.env.PUBLIC_URL}/policies`);
        moveToStep2(data.id, name);
      }
    });
  }

  function updatePolicy(id, name, ids, fileId) {
    // eslint-disable-next-line no-unused-vars
    let params = {
      id: id,
      name: name,
      courseIds: ids,
    };
  }
  function uploadFile(file) {
    if (file.type !== "application/pdf") {
      showErrorToast(AppText.uploadFileTypeError);
    } else if (bytesToMegaBytes(file.size) > 100) {
      showErrorToast(AppText.maxFileSizeError);
    } else {
      let data = file;
      data.isUploading = true;
      data.isError = false;
      data.fileId = null;
      setIsUploading(true);
      setUploadedFile(data);

      uploadPolicyDoc(data, (object, error) => {
        if (error === null) {
          setIsUploading(false);
          data.fileId = object.id;
          data.isUploading = false;
          setUploadedFile(data);
        } else {
          setUploadedFile();
          /// Need to show error toast
        }
      });
    }
  }

  //*********************************************/

  return (
    <React.Fragment>
      <Head title="New policy"></Head>
      <Content>
        <Block className={"justify-center"}>
          <BlockHead size="lg" wide="sm">
            <BlockHeadContent>
              <BlockTitle tag="h3" page>
                Add policy
              </BlockTitle>
              <PreviewAltCard>
                <Form className="row gy-3 mt-n5">
                  <Col md="4" lg="6" sm="12">
                    <div className="form-group">
                      <label className="form-label mt-2 ">Policy name</label>
                      <input
                        className={`form-control wide-sm ${isTitleValid ? "" : "is-invalid"}`}
                        value={formData?.title ?? ""}
                        onChange={onInputChange}
                        type="text"
                        name="name"
                      />
                      {!isTitleValid && <p className="invalid">Please enter a valid title</p>}
                    </div>
                  </Col>
                  <Col size="12">
                    <div className="form-group">
                      <label className="form-label">Select the required roles for this policy</label>
                    </div>
                  </Col>
                  <Col size="12">
                    <div className="form-group">
                      <div className="custom-control custom-control-sm custom-checkbox notext">
                        <input
                          type="checkbox"
                          className="custom-control-input"
                          id="pid-all"
                          checked={selectAll}
                          onChange={toggleSelectAll}
                        />
                        <label className="custom-control-label" htmlFor="pid-all">
                          Select all
                        </label>
                      </div>
                    </div>
                  </Col>
                  {roleList?.map((item, index) => (
                    <React.Fragment key={index}>
                      <Col lg="4" xxl="4" md="6" sm="12" key={item.id}>
                        <div className="form-group ">
                          <span className="nk-tb-col-check">
                            <div className="custom-control custom-control-sm custom-checkbox notext">
                              <input
                                type="checkbox"
                                className="custom-control-input"
                                id={`pid-${item.id}`}
                                checked={item?.checked ?? false}
                                onChange={(e) => onSelectChange(e, item.id)}
                              />
                              <label className="custom-control-label" htmlFor={`pid-${item.id}`}>
                                {item.role}
                              </label>
                            </div>
                          </span>
                        </div>
                      </Col>
                    </React.Fragment>
                  ))}
                  <Col md="12">
                    <div className="form-group mb-0" style={{ marginTop: 14 }}>
                      <label htmlFor="files">
                        <Icon name="clip" className={"mb-1"} /> Attach policy document (only PDF)
                      </label>
                      <input
                        type="file"
                        name="files"
                        accept=".pdf"
                        className="form-control"
                        id="files"
                        style={{ display: "none" }}
                        // multiple
                        // ref={register({ required: true })}
                        onChange={(e) => {
                          if ((e.target.files?.length ?? 0) > 0) {
                            let data = e.target.files[0];
                            let filetype = data.name.split(".");
                            if (
                              filetype[filetype.length - 1] !== "doc" &&
                              filetype[filetype.length - 1] !== "docx" &&
                              filetype[filetype.length - 1] !== "jpg" &&
                              filetype[filetype.length - 1] !== "jpeg" &&
                              filetype[filetype.length - 1] !== "png"
                            ) {
                              uploadFile(data);
                            } else {
                              setFileError(true);
                            }
                          }
                        }}
                      />
                      <div>
                        <div className="d-flex align-items-center">
                          {uploadedFile ? (
                            <>
                              <p className="m-0">{uploadedFile?.name}</p>
                              {isUploading ? (
                                <Spinner className="ms-1" size="sm" color="dark" />
                              ) : (
                                <Icon
                                  style={{ cursor: "pointer" }}
                                  className="text-danger ms-1"
                                  name="cross-circle-fill"
                                  onClick={() => setUploadedFile()}
                                ></Icon>
                              )}
                            </>
                          ) : (
                            <p className={fileError ? "d-block text-danger" : "d-none"}>
                              Please upload .pdf format file.
                            </p>
                          )}
                        </div>
                      </div>
                    </div>
                  </Col>
                  <Col md="12">
                    <div className="form-group">
                      <Button
                        className="btn btn-primary custom-solid-btn-sm"
                        type="button"
                        disabled={isloading}
                        onClick={() => {
                          onFormSubmit();
                        }}
                      >
                        {isloading ? <Spinner size="sm" color="light" /> : "Continue"}
                      </Button>
                    </div>
                  </Col>
                </Form>
              </PreviewAltCard>
            </BlockHeadContent>
          </BlockHead>
        </Block>
      </Content>
    </React.Fragment>
  );
};

export default AddPolicy;
