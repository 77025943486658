import { apiService } from "../../services/api";
import { APIServicePath } from "../../services/api/api-service-impl";

export function signin(data, callback) {
  apiService
    .signIn(data)
    .then((auth) => {
      callback(auth, null);
    })
    .catch((error) => {
      callback(null, error);
    });
}

export function resetPasssword(data, callback) {
  apiService
    .resetPassword(data)
    .then((data) => {
      callback(data, null);
    })
    .catch((error) => {
      callback(null, error);
    });
}
export function checkPassword(data, callback) {
  apiService
    .postCall(APIServicePath.checkPassword, data)
    .then((data) => {
      callback(data, null);
    })
    .catch((error) => {
      callback(null, error);
    });
}
export function changePassword(data, callback) {
  apiService
    .postCall(APIServicePath.changePassword, data)
    .then((data) => {
      callback(data, null);
    })
    .catch((error) => {
      callback(null, error);
    });
}

export function recoverNewPassword(data, callback) {
  apiService
    .recoverPassword(data)
    .then((auth) => {
      callback(auth, null);
    })
    .catch((error) => {
      callback(null, error);
    });
}

export function verifyResetPasswordToken(data, callback) {
  apiService
    .verifyResetPasswordToken(data)
    .then((auth) => {
      callback(auth, null);
    })
    .catch((error) => {
      callback(null, error);
    });
}

export function resendVerifyEmail(data, callback) {
  apiService
    .resendVerifyEmail(data)
    .then((auth) => {
      callback(auth, null);
    })
    .catch((error) => {
      callback(null, error);
    });
}

export function generateSession(data, callback) {
  apiService
    .generateSession(data)
    .then((auth) => {
      callback(auth, null);
    })
    .catch((error) => {
      callback(null, error);
    });
}

export function vendorSetup(image, data, callback) {
  apiService
    .vendorSetup(image, data)
    .then((object) => {
      callback(object, null);
    })
    .catch((error) => {
      callback(null, error);
    });
}

export function getServices(req = {}, callback) {
  apiService
    .fetchServices(req)
    .then((list) => {
      callback(list, null);
    })
    .catch((error) => {
      callback(null, error);
    });
}

export function fetchUserInfo(callback) {
  apiService
    .fetchCurrentUser()
    .then((list) => {
      callback(list, null);
    })
    .catch((error) => {
      callback(null, error);
    });
}
