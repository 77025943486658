import React, { useEffect, useState } from "react";
import { Form } from "reactstrap";
import { Block, Button, Col } from "../../components/Component";
import Head from "../../layout/head/Head";
import { getCourseList } from "../dashboard/Roles/RolesData";
import DatePicker from "react-datepicker";
import { useContext } from "react";
import { AddTrainingWizardContext } from "./TrainingWizard";
import moment from "moment/moment";
import { dateToShowFormate } from "../../utils";

const AddStaffTraining = (props) => {
  const [courseList, setCourseList] = useState([]);
  const [selectAll, setSelectAll] = useState(false);
  const [dueDate, setDueDate] = useState(new Date());

  // const [isTitleValid, setIsTitleValid] = useState(true);
  const { data, updateData } = useContext(AddTrainingWizardContext);
  const [oldCourse] = useState(data);
  useEffect(() => {
    loadCourses();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    updateData("courseListData", courseList);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [courseList]);

  const onSelectChange = (e, id) => {
    const newState = e.currentTarget.checked;
    setCourseList((prevData) => prevData.map((item) => (item.id === id ? { ...item, checked: newState } : item)));
    setSelectAll(courseList?.filter((item) => item.id !== id).every((item) => item.checked) && newState);
  };

  const toggleSelectAll = (e) => {
    const newState = e.currentTarget.checked;
    setSelectAll(newState);
    setCourseList((prevData) => prevData.map((item) => ({ ...item, checked: newState })));
  };

  const onFormSubmit = () => {
    if ((data?.courseListData?.length ?? 0) === 0) {
      return;
    }

    let dueDateStr = moment(dueDate).format("DD-MM-YYYY");
    let list = data?.courseListData.filter((item) => item.checked === true);
    for (let index = 0; index < list.length; index++) {
      const element = list[index];
      if (!element.maxDueAt && !element.completedAt) {
        element.dueInStartAt = null;
        element.maxDueAt = dueDateStr;
        element.isNewSelection = true;
      }
      list[index] = element;
    }
    updateData("courseListData", list);
    props.next();
  };

  // API----------------------------------------------------->

  function getCourse(Id) {
    let course = oldCourse?.courseListData.filter((e) => e.id === Id);
    return course?.length > 0 ? course[0] : null;
  }

  function loadCourses() {
    getCourseList(true, (data, error) => {
      if (error === null) {
        setCourseList(data);
      }
      setCourseList((prevData) =>
        prevData.map((item) => {
          let course = getCourse(item.id);
          return course
            ? {
                ...item,
                checked: true,
                completedAt: course.completedAt ? dateToShowFormate(course.completedAt) : null,
                dueInStartAt: course?.isNewSelection
                  ? null
                  : course.dueInStartAt
                  ? dateToShowFormate(course.dueInStartAt)
                  : null,
                dueIn: course.dueIn ? course.dueIn : item.dueIn,
                maxDueAt: course?.isNewSelection ? null : course.maxDueAt ? dateToShowFormate(course.maxDueAt) : null,
                // frequency: "Every year",
                frequency: course.frequency ? course.frequency : item.frequency,
              }
            : { ...item, checked: false };
        })
      );
    });
  }

  return (
    <>
      <React.Fragment>
        <Head title="add-staff-training"></Head>
        {/* <Content> */}
        <Block className="wide-md mx-auto">
          <Form className="row gy-4 mt-0" onSubmit={onFormSubmit}>
            <Col size="12">
              <div className="form-group">
                <label className="form-label">Select the required courses for this Role</label>
              </div>
            </Col>
            <Col size="12">
              <div className="form-group">
                <div className="custom-control custom-control-sm custom-checkbox notext">
                  <input
                    type="checkbox"
                    className="custom-control-input"
                    id="pid-all"
                    checked={selectAll}
                    onChange={toggleSelectAll}
                  />
                  <label className="custom-control-label" htmlFor="pid-all">
                    Select all
                  </label>
                </div>
              </div>
            </Col>
            {courseList?.map((item) => (
              <Col lg="4" xxl="3" md="6" sm="12" key={item.id}>
                <div className="form-group">
                  <span className="nk-tb-col-check">
                    <div className="custom-control custom-control-sm custom-checkbox notext">
                      <input
                        type="checkbox"
                        className="custom-control-input"
                        id={`pid-${item.id}`}
                        checked={item?.checked ?? false}
                        onChange={(e) => onSelectChange(e, item.id)}
                      />
                      <label className="custom-control-label" htmlFor={`pid-${item.id}`}>
                        {item.title}
                      </label>
                    </div>
                  </span>
                </div>
              </Col>
            ))}
            <h5 className="mt-5">Assigned due date of all selected courses</h5>
            <span className="text-soft">
              <p>If left blank you will be able to change it one by one later.</p>
            </span>
            <Col sm="12" md="3" lg="2">
              <div className="form-group">
                <label className="form-label">Due Date</label>
                <div className="position-relative">
                  {/* <em className="ni fs-5 ni-calender-date position-absolute calender-input-position"></em> */}
                  {/* <em class="ni fs-5 ni-calender-date position-absolute calender-input-position"></em> */}
                  <DatePicker
                    toggleCalendarOnIconClick
                    selected={dueDate}
                    className="form-control"
                    dateFormat={"dd-MM-yyyy"}
                    onChange={(date) => {
                      setDueDate(date);
                    }}
                    minDate={new Date()}
                  />
                </div>
              </div>
            </Col>
            <div className="">
              <ul className="d-flex justify-content-end">
                <li className="mx-1">
                  <Button color="primary" type="submit" className="custom-solid-btn-sm" onClick={onFormSubmit}>
                    Next
                  </Button>
                </li>
              </ul>
            </div>
          </Form>
        </Block>
        {/* </Content> */}
      </React.Fragment>
    </>
  );
};

export default AddStaffTraining;
