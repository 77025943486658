/* eslint-disable no-unused-vars */
import React, { useState } from "react";
import UserAvatar from "../../../../components/user/UserAvatar";
import { DropdownToggle, DropdownMenu, Dropdown } from "reactstrap";
import { Icon } from "../../../../components/Component";
import { LinkList, LinkItem } from "../../../../components/links/Links";
import { useTheme, useThemeUpdate } from "../../../provider/Theme";
import { Navigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { Storage } from "../../../../services/storage/storage";
import { handleSignout, toCapitalize, UserAccountType } from "../../../../utils";

const User = () => {
  const user = Storage.getLoggedInUser();
  const pendingCount = useSelector((state) => state.certificate.pendingCount);

  const theme = useTheme();
  const themeUpdate = useThemeUpdate();
  const [open, setOpen] = useState(false);
  const toggle = () => setOpen((prevState) => !prevState);

  const switchToPermission = () => {
    let data = user;
    if (user?.accountType === UserAccountType.admin) {
      data.accountType = UserAccountType.staff;
      data.isAdmin = true;
    } else if (user?.accountType === UserAccountType.manager) {
      data.accountType = UserAccountType.staff;
      data.isManager = true;
    } else if (user?.accountType === UserAccountType.staff && (data.isManager || data.isAdmin)) {
      data.accountType = data.isAdmin ? UserAccountType.admin : UserAccountType.manager;
    }
    Storage.setLoggedInUser(data);
    <Navigate to={`${process.env.PUBLIC_URL}`} replace />;
    toggle();
  };

  return (
    <Dropdown isOpen={open} className="user-dropdown" toggle={toggle}>
      <DropdownToggle
        tag="a"
        href="#toggle"
        className="dropdown-toggle"
        onClick={(ev) => {
          ev.preventDefault();
        }}
      >
        <div className="user-toggle">
          <UserAvatar icon="user-alt" className="sm" image={user?.avatar?.thumbUrl} />{" "}
          {pendingCount > 0 && <div className="badge-container pending-msg-count">{pendingCount}</div>}
        </div>
      </DropdownToggle>
      <DropdownMenu end className="dropdown-menu-md dropdown-menu-s1">
        <div className="dropdown-inner user-card-wrap bg-lighter d-none d-md-block">
          <div className="user-card sm">
            <UserAvatar icon="user-alt" image={user?.avatar?.thumbUrl} />

            <div className="user-info">
              <span className="lead-text">{(user?.firstName ?? "") + " " + (user?.lastName ?? "")}</span>
              <span className="sub-text">{user?.email ?? ""}</span>
              <span className="sub-text">{toCapitalize(user?.accountType ?? "")}</span>
            </div>
            {/* <div className="user-action" onClick={() => setOpen(false)}>
              <Link to={`${process.env.PUBLIC_URL}/user-profile-setting`} className="btn btn-icon me-n2">
                <Icon name="setting"></Icon>
              </Link>
            </div> */}
          </div>
        </div>

        {/* <div className="dropdown-inner">
          <LinkList>
            <LinkItem link={"/user-profile-regular"} icon="user-alt" onClick={toggle}>
              View Profile
            </LinkItem>
            <LinkItem link={"/user-profile-setting"} icon="setting-alt" onClick={toggle}>
              Account Setting
            </LinkItem>
            <LinkItem link={"/user-profile-activity"} icon="activity-alt" onClick={toggle}>
              Login Activity
            </LinkItem>
            <li>
              <a
                className={`dark-switch ${theme.skin === "dark" ? "active" : ""}`}
                href="#"
                onClick={(ev) => {
                  ev.preventDefault();
                  themeUpdate.skin(theme.skin === "dark" ? "light" : "dark");
                }}
              >
                {theme.skin === "dark" ? (
                  <>
                    <em className="icon ni ni-sun"></em>
                    <span>Light Mode</span>
                  </>
                ) : (
                  <>
                    <em className="icon ni ni-moon"></em>
                    <span>Dark Mode</span>
                  </>
                )}
              </a>
            </li>
          </LinkList>
        </div> */}

        <div className="dropdown-inner">
          <LinkList>
            <LinkItem className={"line-height-18"} link={"/user-certificate"} icon="setting-alt" onClick={toggle}>
              My Certificate
            </LinkItem>
            <LinkItem className={"line-height-18"} link={"/pending-certificate"} icon="clock" onClick={toggle}>
              Pending Certificate
              {/* {pendingCount > 0 && <div className={`pending-msg-count-badge`}>{pendingCount}</div>} */}
            </LinkItem>
            <LinkItem
              className={"line-height-18"}
              link={"/user-archive-certificate"}
              icon="reports-alt"
              onClick={toggle}
            >
              Archive Certificate
            </LinkItem>
            <LinkItem className={"line-height-18"} link={"/check-password"} icon="unlock-fill" onClick={toggle}>
              Change Password
            </LinkItem>
            {user?.accountType !== UserAccountType.staff && (
              <LinkItem
                className={"line-height-18 pt-1"}
                icon="target"
                to={`${process.env.PUBLIC_URL}`}
                onClick={switchToPermission}
              >
                View as Learner
              </LinkItem>
            )}

            {user?.accountType === UserAccountType.staff && (user.isAdmin || user.isManager) && (
              <LinkItem icon="target" to={`${process.env.PUBLIC_URL}`} onClick={switchToPermission}>
                {user.isAdmin ? "View as Admin" : "View as Manager"}
              </LinkItem>
            )}
          </LinkList>
        </div>
        <div className="dropdown-inner">
          <LinkList>
            <a href={`${process.env.PUBLIC_URL}/auth-login`} onClick={handleSignout}>
              <Icon name="signout"></Icon>
              <span>Sign Out</span>
            </a>
          </LinkList>
        </div>
      </DropdownMenu>
    </Dropdown>
  );
};

export default User;
