import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router";

import {
  Block,
  BlockBetween,
  BlockHead,
  BlockHeadContent,
  BlockTitle,
  Button,
  DataTable,
  DataTableBody,
  DataTableHead,
  DataTableItem,
  DataTableRow,
  PaginationComponent,
} from "../../components/Component";
import ProgressColor from "../../components/progress/ProgressColor";
import Content from "../../layout/content/Content";
import Head from "../../layout/head/Head";
import { LocationDropDown } from "../dashboard/Location/LocationDropDown";
import { fetchPolicyStatisticsList } from "./PolicyData";
import { Storage } from "../../services/storage/storage";
import { UserAccountType } from "../../utils";
const PolicyList = () => {
  const locationState = useLocation();

  const navigate = useNavigate();
  const [policyStatisticsListList, setPolicyStatisticsListList] = useState([]);
  const [location, setLocation] = useState(locationState.state?.location ?? null);
  // const [searchText, setSearchText] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [itemPerPage] = useState(20);
  const [totalItems, setTotalItems] = useState(0);
  const [isInitScreen, setIsInitScreen] = useState(true);

  const user = Storage.getLoggedInUser();

  useEffect(() => {
    if (isInitScreen) {
      return;
    }
    if (currentPage !== 1) {
      setCurrentPage(1);
    } else {
      loadStatisticsList();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  useEffect(() => {
    loadStatisticsList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage]);

  const onAddClick = () => {
    navigate(`${process.env.PUBLIC_URL}/add-policy`);
  };

  const onMatrixClick = () => {
    navigate(`${process.env.PUBLIC_URL}/policies`);
  };

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  function loadStatisticsList() {
    const params = {
      pageInfo: {
        pageIndex: currentPage,
        itemsPerPage: itemPerPage,
      },
      locationIds: location ? [location.id] : [],
    };

    fetchPolicyStatisticsList(params, (data, error) => {
      if (error === null) {
        setPolicyStatisticsListList(data.list);
        setTotalItems(data.pageInfo?.totalItems ?? 0);
      }
      setIsInitScreen(false);
    });
  }

  return (
    <React.Fragment>
      <Head title="Policies" />
      <Content>
        <div className="wide-lg mx-auto">
          <BlockHead size="sm">
            <BlockBetween>
              <BlockHeadContent>
                <BlockTitle tag="h3" page>
                  Policies
                </BlockTitle>
              </BlockHeadContent>
              <BlockHeadContent>
                {user?.accountType === UserAccountType.admin && (
                  <Button color="primary" className="btn-text custom-solid-btn-sm" onClick={onAddClick}>
                    Add policy
                  </Button>
                )}

                <Button color="primary" className="btn-text ms-2 custom-solid-btn-sm" onClick={onMatrixClick}>
                  Matrix
                </Button>
              </BlockHeadContent>
            </BlockBetween>
          </BlockHead>
          <Block>
            <DataTable className="card-stretch">
              <div className="card-inner position-relative card-tools-toggle">
                <div className="card-title-group">
                  <div className="card-tools">
                    <div className="form-inline flex-nowrap gx-2">
                      <div className="form-wrap">
                        <LocationDropDown value={location} className="w-225px" onSelect={(e) => setLocation(e)} />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <DataTableBody>
                <DataTableHead>
                  <DataTableRow>
                    <span className="sub-text">
                      <strong>Policy name</strong>
                    </span>
                  </DataTableRow>
                  <DataTableRow>
                    <span className="sub-text ">
                      <strong>Total compliance %</strong>
                    </span>
                  </DataTableRow>
                  <DataTableRow>
                    <span className="sub-text ">
                      <strong>Compliant staff</strong>
                    </span>
                  </DataTableRow>
                  {/* {user?.accountType === UserAccountType.admin && (
                  <DataTableRow className="nk-tb-col-tools text-end">
                    <span className="sub-text"></span>
                  </DataTableRow>
                )} */}
                </DataTableHead>
                {/*Head*/}
                {policyStatisticsListList.length > 0
                  ? policyStatisticsListList.map((item, index) => {
                      return (
                        <DataTableItem
                          key={index}
                          className="cursor-pointer"
                          onClick={() => {
                            navigate(`${process.env.PUBLIC_URL}/policy-view`, {
                              state: {
                                data: item,
                              },
                            });
                          }}
                        >
                          <DataTableRow>
                            <div className="user-info">
                              <span className="tb-lead">{item.name} </span>
                            </div>
                          </DataTableRow>
                          <DataTableRow>
                            <ProgressColor value={item.avgCompliance} />
                          </DataTableRow>
                          <DataTableRow>
                            <div className="project-progress ">
                              <div className="project-progress-percent">
                                {item.totalComplianceStaff} / {item.totalStaff}
                              </div>
                            </div>
                          </DataTableRow>
                        </DataTableItem>
                      );
                    })
                  : null}
              </DataTableBody>
              {totalItems > policyStatisticsListList.length && (
                <div className="card-inner">
                  {totalItems > 0 && totalItems > policyStatisticsListList.length ? (
                    <PaginationComponent
                      itemPerPage={itemPerPage}
                      totalItems={totalItems}
                      paginate={paginate}
                      currentPage={currentPage}
                    />
                  ) : totalItems === 0 ? (
                    <div className="text-center">
                      <span className="text-silent">No data found</span>
                    </div>
                  ) : null}
                </div>
              )}
            </DataTable>
          </Block>
        </div>
      </Content>
    </React.Fragment>
  );
};

export default PolicyList;
