import React, { useEffect, useState } from "react";
import {
  Block,
  BlockBetween,
  BlockHead,
  BlockHeadContent,
  BlockTitle,
  PreviewAltCard,
} from "../../components/Component";
import { Button } from "reactstrap";
import Content from "../../layout/content/Content";
import CalenderApp from "../../components/partials/calender/Calender";
import { events } from "../../components/partials/calender/CalenderData";
import AddFaceEvent from "./AddFaceEvent";
import { getFaceToFaceList } from "./FaceToFaceData";
import moment from "moment/moment";
import { useLocation, useNavigate } from "react-router-dom";
import SetLocationFilter from "../Stafflist/SetLocationFilter";
import RemoveableBadge from "../../components/removable-badge/RemovableBadge";
import { Storage } from "../../services/storage/storage";
import { showErrorToast, UserAccountType } from "../../utils";

const FaceToFaceCalendar = () => {
  const locationState = useLocation();
  const [modal, setModal] = useState(false);
  const [locationModal, setLocationModal] = useState({
    edit: false,
    add: false,
    location: false,
  });
  const [location, setLocation] = useState(locationState.state?.location ? [locationState.state?.location] : []);
  const [dateinfo, setDateInfo] = useState();
  const [traningData, setTraningData] = useState([]);
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const [mockEvents, updateEvent] = useState(events);
  const navigate = useNavigate();
  const user = Storage.getLoggedInUser();

  useEffect(() => {
    if (startDate && endDate) {
      getFaceToFaceListData(startDate, endDate);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  const editEvent = (formData) => {
    let newEvents = mockEvents;
    const index = newEvents.findIndex((item) => item.id === formData.id);
    events[index] = formData;
    updateEvent([...events]);
  };
  const addEvent = (info) => {
    if (user?.accountType !== UserAccountType.admin) {
      return;
    } else if (info.date > new Date() || moment().isSame()) {
      setDateInfo(info.date);
      setModal(true);
    } else {
      showErrorToast("You can create training to future date only.");
    }
  };

  const onEventClick = (info) => {
    navigate(`${process.env.PUBLIC_URL}/face-to-face-detail`, {
      state: { trainingData: info },
    });
  };
  const deleteEvent = (id) => {
    let filteredEvents = mockEvents.filter((item) => item.id !== id);
    updateEvent(filteredEvents);
  };
  const getFaceToFaceListData = (start, end) => {
    setStartDate(start);
    setEndDate(end);

    let params = {
      startDate: moment(start).format("YYYY-MM-DD"),
      endDate: moment(end).format("YYYY-MM-DD"),
      locationIds: location?.map((item) => item.id),
    };

    getFaceToFaceList(params, (data, error) => {
      let list = [];
      // eslint-disable-next-line array-callback-return
      data?.list?.map((value, index) => {
        const { id, title, dateTime, duration, userData } = value;
        let date = moment(dateTime);
        let endDate = moment(dateTime).add(duration, "minutes");

        let event = {
          id: "default-" + id,
          title: title,
          start: date.format("YYYY-MM-DD HH:mm:ss"),
          end: endDate.format("YYYY-MM-DD HH:mm:ss"),
          className: `${
            moment().isAfter(endDate, "YYYY-MM-DD HH:mm:ss")
              ? "fc-event-success"
              : userData?.userType === "MANAGER"
              ? "fc-event-orange"
              : "fc-event-primary"
          }`,
          object: value,
        };
        list.push(event);
      });
      setTraningData(list);
    });
  };

  function handleRemoveLocation(index) {
    const updatedLocations = [...location]; // create a copy of the location array
    updatedLocations.splice(index, 1); // remove the location at the specified index
    setLocation(updatedLocations); // update the state with the new location array
  }

  return (
    <React.Fragment>
      <Content>
        <BlockHead size="sm">
          <BlockBetween>
            <BlockHeadContent>
              <BlockTitle tag="h3" page>
                Face-to-face
              </BlockTitle>
              {location.length > 0 && (
                <div className="mt-2">
                  <span className="me-2">
                    <u></u>Locations:
                  </span>
                  {location.map((item, index) => (
                    <RemoveableBadge key={index} label={item.label} onRemove={() => handleRemoveLocation(index)} />
                  ))}
                  {locationState?.state?.isOverdue && (
                    <span className="badge bg-outline-danger ms-1 fs-4 p-1">Overdue</span>
                  )}
                </div>
              )}
            </BlockHeadContent>
            {/* {user?.accountType === UserAccountType.admin && ( */}
            <BlockHeadContent>
              <span
                style={{ cursor: "pointer" }}
                className="custom-outline-btn bg-white position-relative me-2"
                onClick={() => setLocationModal({ location: true })}
              >
                Locations
                <em className="ni ms-1 fs-4 ni-filter-alt"></em>
                {location !== null && location.length > 0 ? <span className="active-dot-primary"></span> : null}
              </span>

              <Button color="primary" className="btn-text custom-solid-btn-sm" onClick={() => setModal(true)}>
                Add FTF Training
              </Button>
            </BlockHeadContent>

            {/* )} */}
          </BlockBetween>
        </BlockHead>
        <Block>
          <PreviewAltCard>
            <CalenderApp
              events={traningData}
              onDelete={deleteEvent}
              onEdit={editEvent}
              onAdd={addEvent}
              onEvent={onEventClick}
              onDateChange={(start, end) => {
                getFaceToFaceListData(start, end);
              }}
            />
          </PreviewAltCard>
        </Block>
      </Content>
      <AddFaceEvent
        dataInfo={dateinfo}
        visible={modal}
        isAddTraining={true}
        onClosed={() => {
          setModal(false);
          setDateInfo();
          getFaceToFaceListData(startDate, endDate);
        }}
      />
      <SetLocationFilter
        visible={locationModal.location}
        selectedLocations={location}
        // item={editItem}
        onChanged={(locations) => {
          setLocation(locations);
        }}
        onClosed={() => {
          setLocationModal({ location: false });
        }}
      />
    </React.Fragment>
  );
};
export default FaceToFaceCalendar;
