import React, { useEffect, useState } from "react";
import Content from "../../layout/content/Content";
import Head from "../../layout/head/Head";
import {
  Block,
  BlockHead,
  BlockHeadContent,
  BlockTitle,
  UserAvatar,
  PaginationComponent,
  Button,
  DataTable,
  DataTableBody,
  DataTableHead,
  DataTableRow,
  DataTableItem,
  RSelect,
} from "../../components/Component";
import { fetchStaff } from "./StaffData";
import SetLocationFilter from "./SetLocationFilter";
import SetRolesFilter from "./SetRolesFilter";
import { useLocation, useNavigate } from "react-router";
import ProgressColor from "../../components/progress/ProgressColor";
import { showErrorToast, StatusOptionWithAll, UserAccountType, ColorsArray, findUpper, toCapitalize } from "../../utils";
import LoadingComponent from "../../components/spinner/LoadingSpinner";
import RemoveableBadge from "../../components/removable-badge/RemovableBadge";
import { Storage } from "../../services/storage/storage";

var token = {};
const Stafflist = () => {
  const locationState = useLocation();
  const [isInitScreen, setIsInitScreen] = useState(true);
  const [staffList, setStaffList] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [modal, setModal] = useState({
    edit: false,
    add: false,
    location: false,
  });
  const [status, setStatus] = useState(StatusOptionWithAll[1]);
  const [roles, setRoles] = useState([]);
  const [location, setLocation] = useState(locationState.state?.location ? [locationState.state?.location] : []);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemPerPage] = useState(15);
  const [totalItems, setTotalItems] = useState(0);
  const navigate = useNavigate();

  const user = Storage.getLoggedInUser();

  // Changing state value when searching name
  useEffect(() => {
    if (currentPage !== 0) {
      loadStaff();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage]);

  // Changing state value when searching name
  useEffect(() => {
    if (isInitScreen) {
      return;
    }

    token?.cancel && token?.cancel();
    if (currentPage === 1) {
      loadStaff();
    } else {
      setCurrentPage(1);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchText, roles, status, location]);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  function loadStaff() {
    fetchStaff(
      currentPage,
      itemPerPage,
      searchText,
      status,
      location.map((item) => item.id),
      roles.map((item) => item.id),
      locationState?.state?.isOverdue,
      token,
      (data, error) => {
        if (error === null) {
          setStaffList(data.list ?? []);
          if (currentPage === 1) {
            setTotalItems(data.pageInfo?.totalItems ?? 0);
          }
        }
        setIsInitScreen(false);
      }
    );
  }

  function handleRemoveRole(index) {
    const updatedRoles = [...roles]; // create a copy of the roles array
    updatedRoles.splice(index, 1); // remove the role at the specified index
    setRoles(updatedRoles); // update the state with the new roles array
  }

  function handleRemoveLocation(index) {
    const updatedLocations = [...location]; // create a copy of the location array
    updatedLocations.splice(index, 1); // remove the location at the specified index
    setLocation(updatedLocations); // update the state with the new location array
  }

  const onAddClick = () => {
    navigate(`${process.env.PUBLIC_URL}/add-staff`);
  };

  const showStaffClick = (item) => {
    let currentUser = Storage.getLoggedInUser();

    if (
      currentUser &&
      ((currentUser.id !== item.id && item.accountType !== UserAccountType.admin) ||
        currentUser?.accountType === UserAccountType.admin)
    ) {
      navigate(`${process.env.PUBLIC_URL}/staff-training`, {
        state: { id: item.id },
      });
    } else {
      showErrorToast("You can not able to view and modify your own/admin profile");
    }
  };

  return (
    <React.Fragment>
      <Head title="staff-list"></Head>
      {!isInitScreen && (
        <Content>
          <BlockHead size="sm">
            <div className="d-flex flex-column flex-md-row align-items-md-center justify-content-md-between">
              <BlockHeadContent className="mb-2 mb-md-0">
                <BlockTitle tag="h3" page>
                  Staff list{" "}
                  {locationState?.state?.isOverdue && (
                    <span className="badge bg-outline-danger ms-1 fs-4 p-1">Overdue</span>
                  )}
                </BlockTitle>
              </BlockHeadContent>
              <BlockHeadContent>
                {(user?.accountType === UserAccountType.admin || user?.accountType === UserAccountType.manager) && (
                  <Button color="primary" className="d-sm-inline-flex custom-solid-btn-sm" onClick={onAddClick}>
                    Add Staff
                  </Button>
                )}

                <Button
                  color="primary"
                  className="ms-1 custom-solid-btn-sm"
                  onClick={() => {
                    navigate(`${process.env.PUBLIC_URL}/roles-list`);
                  }}
                >
                  Roles
                </Button>
              </BlockHeadContent>
            </div>
          </BlockHead>

          <Block>
            <DataTable className="card-stretch">
              <div className="card-inner position-relative card-tools-toggle">
                <div className="card-title-group d-flex align-items-start align-items-md-center flex-column flex-md-row">
                  <div className="card-tools mb-2 mb-md-0">
                    <div className="form-inline flex-nowrap gx-2">
                      <div className="form-wrap">
                        <input
                          className="form-control "
                          type="text"
                          name="search"
                          value={searchText}
                          placeholder="Search"
                          onChange={(e) => {
                            setSearchText(e.target.value);
                          }}
                        />
                      </div>
                      <div className="form-wrap">
                        <RSelect
                          options={StatusOptionWithAll}
                          value={status}
                          className="w-130px "
                          placeholder="Status"
                          onChange={(e) => setStatus(e)}
                        />
                      </div>
                    </div>
                    {location.length > 0 && (
                      <div className="mt-2">
                        <span className="me-2">
                          <u></u>Locations:
                        </span>
                        {location.map((item, index) => (
                          <RemoveableBadge
                            key={index}
                            label={item.label}
                            onRemove={() => handleRemoveLocation(index)}
                          />
                        ))}
                      </div>
                    )}
                    {roles.length > 0 && (
                      <div className="mt-1">
                        <span className="me-2">Roles:</span>
                        {roles.map((item, index) => (
                          <RemoveableBadge key={index} label={item.role} onRemove={() => handleRemoveRole(index)} />
                        ))}
                      </div>
                    )}
                  </div>
                  <div>
                    <span
                      style={{ cursor: "pointer" }}
                      className="custom-outline-btn bg-white position-relative"
                      onClick={() => setModal({ location: true })}
                    >
                      Locations
                      <em className="ni ms-1 fs-4 ni-filter-alt"></em>
                      {location !== null && location.length > 0 ? <span className="active-dot-primary"></span> : null}
                    </span>
                    <span
                      style={{ cursor: "pointer" }}
                      className=" ms-4 custom-outline-btn bg-white position-relative"
                      onClick={() => setModal({ roles: true })}
                    >
                      Roles
                      <em className="ni ms-1 fs-4 ni-filter-alt"></em>
                      {roles !== null && roles.length > 0 ? <span className="active-dot-primary"></span> : null}
                    </span>
                  </div>
                </div>
              </div>
              <DataTableBody>
                <DataTableHead>
                  <DataTableRow>
                    <span className="sub-text">Staff name</span>
                  </DataTableRow>
                  <DataTableRow size="mb">
                    <span className="sub-text">Role</span>
                  </DataTableRow>
                  <DataTableRow size="md">
                    <span className="sub-text">Status</span>
                  </DataTableRow>
                  <DataTableRow size="lg">
                    <span className="sub-text">Last login</span>
                  </DataTableRow>
                  <DataTableRow size="md">
                    <span className="sub-text">Compliance</span>
                  </DataTableRow>
                </DataTableHead>
                {/*Head*/}
                {staffList.length > 0
                  ? staffList.map((item, index) => {
                      return (
                        <React.Fragment key={index}>
                          <DataTableItem className={"cursor"} onClick={() => showStaffClick(item)}>
                            <DataTableRow>
                              <div className="user-card">
                                <UserAvatar
                                  theme={ColorsArray[Math.floor(Math.random() * ColorsArray?.length)]}
                                  text={
                                    findUpper(toCapitalize(item.name.split(" ", 2)[0])) +
                                    findUpper(toCapitalize(item.name.split(" ", 2)[1]))
                                  }
                                  image={item?.image}
                                ></UserAvatar>

                                <div className="user-info">
                                  <span className="tb-lead mt-1">
                                    {toCapitalize(item.name)}
                                    {""}
                                  </span>
                                  <p className="text-gray">{item?.location}</p>
                                </div>
                              </div>
                              {/* </Link> */}
                            </DataTableRow>
                            <DataTableRow size="mb">
                              <div className="user-info">
                                <span className="tb-lead">{item.role} </span>
                              </div>
                            </DataTableRow>
                            <DataTableRow size="md">
                              <div className="user-info">
                                <span className={`badge bg-${item?.status === "Active" ? "success" : "warning"}`}>
                                  {item.status}
                                </span>
                              </div>
                            </DataTableRow>
                            <DataTableRow size="lg">
                              <div className="user-info">
                                <span
                                  className={`tb-lead ${
                                    item.login ?? "badge badge-dim bg-gray d-inline text-dark fs-0_775rem py-1 px-2"
                                  }`}
                                >
                                  {item.login ?? "Invitation sent"}{" "}
                                </span>
                              </div>
                            </DataTableRow>
                            <DataTableRow size="md">
                              <div className="project-progress item ">
                                <ProgressColor value={item.compliance} />
                              </div>
                            </DataTableRow>
                          </DataTableItem>
                        </React.Fragment>
                      );
                    })
                  : null}
              </DataTableBody>
              <div className="card-inner">
                {totalItems > 0 ? (
                  <PaginationComponent
                    itemPerPage={itemPerPage}
                    totalItems={totalItems}
                    paginate={paginate}
                    currentPage={currentPage}
                  />
                ) : (
                  <div className="text-center">
                    <span className="text-silent">No data found</span>
                  </div>
                )}
              </div>
            </DataTable>
          </Block>

          <SetRolesFilter
            visible={modal.roles}
            selectedRoles={roles}
            onChanged={(roles) => {
              setRoles(roles);
            }}
            onClosed={() => {
              setModal({ roles: false });
            }}
          />
          <SetLocationFilter
            visible={modal.location}
            selectedLocations={location}
            // item={editItem}
            onChanged={(locations) => {
              setLocation(locations);
            }}
            onClosed={() => {
              setModal({ location: false });
            }}
          />
        </Content>
      )}
      <LoadingComponent isLoading={isInitScreen} />
    </React.Fragment>
  );
};

export default Stafflist;
