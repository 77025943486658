import React from "react";
import Head from "../../layout/head/Head";
import { useState } from "react";
import { useEffect } from "react";
import {
  Block,
  BlockBetween,
  BlockHead,
  BlockHeadContent,
  BlockTitle,
  Button,
  DataTable,
  DataTableBody,
  DataTableHead,
  DataTableItem,
  DataTableRow,
  PaginationComponent,
} from "../../components/Component";
import Content from "../../layout/content/Content";
import { LocationDropDown } from "../dashboard/Location/LocationDropDown";
import { bookFaceToFace, getFaceToFaceMyList } from "./FaceToFaceData";
import moment from "moment/moment";
import Swal from "sweetalert2";

import { Nav, NavItem, NavLink, Spinner } from "reactstrap";
import classNames from "classnames";
import { AppText, FTFBookingStatus, showErrorToast, toCapitalize } from "../../utils";
import LoadingComponent from "../../components/spinner/LoadingSpinner";
import { Storage } from "../../services/storage/storage";
import { useNavigate } from "react-router";

const LearnerFaceToFace = ({ ...props }) => {
  const [meetings, setMeetings] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [location, setLocation] = useState();
  const [itemPerPage] = useState(40);
  const [totalItems, setTotalItems] = useState(0);
  const [isInitScreen, setIsInitScreen] = useState(true);
  const [trainingId, setTrainingId] = useState();
  const [activeTab, setActivetab] = useState("1");
  const navigate = useNavigate();

  useEffect(() => {
    if (currentPage !== 0) {
      fetchFaceToFaceList();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage]);

  // Changing state value when searching name
  useEffect(() => {
    if (isInitScreen) {
      return;
    }
    if (currentPage === 1) {
      fetchFaceToFaceList();
    } else {
      setCurrentPage(1);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchText, location, activeTab]);

  // Get current list, pagination
  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  function fetchFaceToFaceList() {
    setMeetings([]);
    let type;
    switch (activeTab) {
      case "1":
        type = "UPCOMING";
        break;
      case "3":
        type = "PAST";
        break;
      case "2":
        type = "BOOKED";
        break;
      default:
      // code block
    }
    // let type = activeTab === "1" ? "UPCOMING" : "PAST";
    getFaceToFaceMyList(searchText, location?.id, type, (data, error) => {
      if (error === null) {
        setIsInitScreen(false);
        setMeetings(data.list);
        if (currentPage === 1) {
          setTotalItems(data.length ?? 0);
        }
      } else {
        setMeetings([]);
      }
    });
  }

  function fetchFaceToFaceBooking(item, index) {
    Swal.fire({
      title: "Are you sure?",
      text: `Do you want to book FTF training for ${item.title}?`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Book",
      confirmButtonColor: "primary",
    }).then((result) => {
      if (result.isConfirmed) {
        const params = {
          id: item.id,
        };
        setTrainingId(item.id);
        bookFaceToFace(params, (data, error) => {
          if (error === null) {
            setMeetings(meetings.filter((object) => object.id !== item.id));
          } else {
            showErrorToast(error.message?.length > 0 ? error.message : AppText.connectError);
          }
          setTrainingId();
        });
      }
    });
  }

  function showFTFDetail(info) {
    navigate(`${process.env.PUBLIC_URL}/face-to-face-detail`, {
      state: { trainingData: info },
    });
  }
  return (
    <>
      <Head title="Face-to-face" />
      <Content c>
        <div className="wide-lg mx-auto position-relative">
          <BlockHead size="sm">
            <BlockBetween>
              <BlockHeadContent>
                <BlockTitle tag="h3" page>
                  Face-to-face meetings
                </BlockTitle>
              </BlockHeadContent>
            </BlockBetween>
          </BlockHead>

          <Block>
            <div className="card-inner pb-1 position-relative card-tools-toggle bg-white">
              <div className="card-title-group">
                <div className="card-tools">
                  <div className="form-inline flex-md-nowrap gx-2">
                    <div className="form-wrap mb-1 mb-sm-0">
                      <input
                        className="form-control w-250px"
                        type="text"
                        name="search"
                        value={searchText}
                        placeholder="Search"
                        onChange={(e) => {
                          setSearchText(e.target.value);
                        }}
                      />
                    </div>
                    <div className="form-wrap">
                      <LocationDropDown value={location} className="w-250px" onSelect={(e) => setLocation(e)} />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div>
              <Nav tabs className="bg-white px-2 border-0">
                <NavItem>
                  <NavLink
                    tag="a"
                    href="#tab"
                    className={classNames({ active: activeTab === "1" })}
                    onClick={(ev) => {
                      ev.preventDefault();
                      setActivetab("1");
                    }}
                  >
                    Upcoming
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    tag="a"
                    href="#tab"
                    className={classNames({ active: activeTab === "2" })}
                    onClick={(ev) => {
                      ev.preventDefault();
                      setActivetab("2");
                    }}
                  >
                    Booked
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    tag="a"
                    href="#tab"
                    className={classNames({ active: activeTab === "3" })}
                    onClick={(ev) => {
                      ev.preventDefault();
                      setActivetab("3");
                    }}
                  >
                    Past
                  </NavLink>
                </NavItem>
              </Nav>
              <DataTable className="card-stretch mx-0">
                <DataTableBody>
                  {meetings.length > 0 ? (
                    <>
                      <DataTableHead>
                        <DataTableRow>
                          <span className="sub-text">
                            <strong>Title</strong>
                          </span>
                        </DataTableRow>
                        <DataTableRow size={"md"}>
                          <span className="sub-text">
                            <strong>Location</strong>
                          </span>
                        </DataTableRow>
                        <DataTableRow>
                          <span className="sub-text ">
                            <strong>Date / Time</strong>
                          </span>
                        </DataTableRow>
                        {activeTab === "1" && (
                          <DataTableRow size={"md"}>
                            <span className="sub-text ">
                              <strong>Available seats</strong>
                            </span>
                          </DataTableRow>
                        )}
                        <DataTableRow className="nk-tb-col-tools text-end">
                          <span className="sub-text"></span>
                        </DataTableRow>
                      </DataTableHead>
                      {/*Head*/}

                      {meetings.length > 0
                        ? meetings.map((item, index) => {
                            const user = Storage.getLoggedInUser(); // Call the function to get the user data
                            const isSameUser = user?.id === item?.userId;
                            const isButtonDisabled = item.totalBooking >= item.totalSeats;
                            return (
                              <DataTableItem key={index}>
                                <DataTableRow>
                                  <div
                                    className="user-info cursor"
                                    onClick={(e) => {
                                      e.preventDefault();
                                      showFTFDetail(item);
                                    }}
                                  >
                                    <span className="tb-lead">{toCapitalize(item?.title ?? "")}</span>
                                  </div>
                                </DataTableRow>
                                <DataTableRow size={"md"}>
                                  <div className="user-info">
                                    <span className="tb-lead">
                                      {item?.isZoom === true ? "Zoom" : item?.location?.name}{" "}
                                    </span>
                                  </div>
                                </DataTableRow>
                                <DataTableRow>
                                  <span>{moment(item.dateTime).format("DD-MM-YYYY / hh:mm A")}</span>
                                </DataTableRow>
                                {activeTab === "1" && (
                                  <DataTableRow size={"md"}>
                                    <div className="project-progress  ">
                                      <div className="project-progress-percent">
                                        {item.totalSeats - item.totalBooking}
                                      </div>
                                    </div>
                                  </DataTableRow>
                                )}
                                {!isSameUser && (
                                  <DataTableRow>
                                    {item?.myBooking ? (
                                      <>
                                        {item?.myBooking?.status === FTFBookingStatus.applied ? (
                                          <div className="project-progress py-1 text-end">
                                            <span className="badge badge-dim badge-info text-dark fs-0_775rem py-1 px-2">
                                              Booked
                                            </span>
                                          </div>
                                        ) : item?.myBooking?.status === FTFBookingStatus.absent ? (
                                          <div className="project-progress py-1 text-end text-dark fw-normal ">
                                            <span className="badge badge-dim bg-danger text-dark fs-0_775rem py-1 px-2">
                                              Absent
                                            </span>
                                          </div>
                                        ) : item?.myBooking?.status === FTFBookingStatus.attended ? (
                                          <div className="project-progress text-end">
                                            <span className="badge badge-dim bg-success text-dark fs-0_775rem py-1 px-2">
                                              Attended
                                            </span>
                                          </div>
                                        ) : (
                                          <></>
                                        )}
                                      </>
                                    ) : (
                                      <>
                                        {isButtonDisabled ? (
                                          <div className="project-progress py-1 text-end fw-normal text-dark">
                                            {"Fully Booked"}
                                          </div>
                                        ) : (
                                          <div className="project-progress text-end">
                                            {trainingId && trainingId === item.id ? (
                                              <Spinner size="sm" color="primary" />
                                            ) : (
                                              <Button
                                                className={`btn-text text-primary m-0 p-0 fw-normal`}
                                                onClick={(e) => fetchFaceToFaceBooking(item, index)}
                                              >
                                                Book
                                              </Button>
                                            )}
                                          </div>
                                        )}
                                      </>
                                    )}
                                  </DataTableRow>
                                )}
                              </DataTableItem>
                            );
                          })
                        : null}
                    </>
                  ) : (
                    <p className="p-2 text-center">You have not been assigned any meeting</p>
                  )}
                </DataTableBody>
                {totalItems > meetings.length && (
                  <div className="card-inner">
                    {totalItems > 0 && totalItems > meetings.length ? (
                      <PaginationComponent
                        itemPerPage={itemPerPage}
                        totalItems={totalItems}
                        paginate={paginate}
                        currentPage={currentPage}
                      />
                    ) : totalItems === 0 ? (
                      <div className="text-center">
                        <span className="text-silent">No data found</span>
                      </div>
                    ) : null}
                  </div>
                )}
              </DataTable>
            </div>
          </Block>
          <LoadingComponent isLoading={isInitScreen} />
        </div>
      </Content>
    </>
  );
};

export default LearnerFaceToFace;
